import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logodark from "../../assets/images/logo/logo.png";
import facebook from "../../assets/images/icon/ic_facebook.svg"
import instagram from "../../assets/images/icon/ic_instagram.svg"
// import linkdien from "../../assets/images/icon/ic_linkdien.svg"
// import telegram from "../../assets/images/icon/ic_telegram.svg"
import youtube from "../../assets/images/icon/ic_you_tube.svg"
import twitter from "../../assets/images/icon/ic_twitter.svg"
import tikTok from "../../assets/images/icon/IC_tik_tok.svg"
const Footer = () => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate(`/about-us`);
        setTimeout(() => {
            const element = document.getElementById("shipping1");
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }, 500);
    };

    return (
        <div>
            <section className="footer">
                <div className="themesflat-container">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12 hideSmall">
                            <div className="sc-box footer-logo">
                                <Link to="/" rel="home" className="footerlogo">
                                    <img src={logodark} alt="nft-gaming" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="sc-box">
                                        <h4 className="heading"> Blockchain Address </h4>
                                        <p ><a className="content blockchainKey" target=" _blank" href="https://goerli.etherscan.io/address/0x5F581b3F61bE3ee721533bE96fAF8c795236a1f2">0x5F581b3F61bE3ee721533bE96fAF8c795236a1f2</a></p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="sc-box">
                                        <h4 className="heading"> Follow Us On </h4>
                                        <div className="social-media">
                                            <a href="https://www.instagram.com/paiverseofficial/" className="socialLogo" target="_blank" rel="noreferrer">
                                                <img src={instagram} alt="nft-gaming" />
                                            </a>
                                            <a href="https://twitter.com/paiverse_" className="socialLogo" target="_blank" rel="noreferrer">
                                                <img src={twitter} alt="nft-gaming" />
                                            </a>
                                            <a href="https://www.facebook.com/Paiverse" target="_blank" className="socialLogo" rel="noreferrer" >
                                                <img src={facebook} alt="nft-gaming" />
                                            </a>

                                            <a href="https://www.youtube.com/channel/UCDwnQM1Dn6lmIn3Jqdrzpzw" className="socialLogo" target="_blank" rel="noreferrer">
                                                <img src={youtube} alt="nft-gaming" />
                                            </a>
                                            <a href="https://www.tiktok.com/@_paiverse" className="socialLogo" target="_blank" rel="noreferrer">
                                                <img src={tikTok} alt="nft-gaming" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="sc-box">
                                        <h4 className="heading"> Address </h4>
                                        <p className="content">Paladin Digital Assets Inc<br />

                                            Ocean Centre, Montagu Foreshore, East Bay Street<br />
                                            P.O. Box SS-19084, Nassau, Bahamas</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="sc-box">
                                        <h4 className="heading"> Contact us </h4>
                                        <p className="content"><a className="text-white" href="mailto:paiverse@paladininvesting.com">paiverse@paladininvesting.com</a></p>
                                        {/* <p className="content"> +1 (847)553-1100 </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer" className="footer-light-style clearfix">
                <div className="themesflat-container">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12 copyRightWrap">
                            <p className="copyRight">&copy; 2023 Paiverse.io </p>
                        </div>
                        <div className="col-md-9 col-12 footerNav">
                            <ul>
                                <li>
                                    <Link to="/release">Releases</Link>
                                    <Link to="/latest-news">Latest News</Link>
                                </li>

                                <li>
                                    <Link to="/marketplace">Marketplace</Link>
                                    <button onClick={handleClick}>Shipping & Insurance</button>
                                </li>
                                <li>
                                    <Link to="/latest-news">News</Link>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/faq">FAQs</Link>
                                    <Link to="/roadmap">Roadmap</Link>
                                </li>
                                <li>
                                    <Link to="/terms-Condition">Terms of Service</Link>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default Footer;
