import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "../axiosinstance";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import LoadingOverlay from "react-loading-overlay";
import apiUrl from "../store/globalapiurl";
import * as moment from 'moment'
const PrivacyPolicy = () => {
    LoadingOverlay.propTypes = undefined
    let [alldata, setData] = React.useState([]);
    let [loading, setLoading] = useState(true);
    React.useEffect(() => {
        getList();
    }, [])
    const getList = () => {
        const payload = {
            type: 2
        };
        axios
            .get(apiUrl._getPage, { params: payload }).then((res) => {
                let data = res.data.data;
                setData(data);
                setLoading(false);
            })
            .catch((error) => {

            });
    };
    return (
        <>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">

                <section className='flat-title-page inner'>
                    <h1 className="heading text-white">{alldata.title ? alldata.title : 'Privacy Policy'} </h1>
                    <p>Last Updated: {alldata.updatedAt ? moment(alldata.updatedAt).format('MMMM YYYY') : ''}</p>
                </section>
                <section className='paiverseTeam  text-left darkGray '>
                    <div className="themesflat-container  tf-section">
                        <div className='termsWrap'>
                            <div dangerouslySetInnerHTML={{ __html: alldata.description }} />

                            {/* <p> How to Contact Us <br />
                                If you have a technical or support question, please contact <Link to={'mailto:contactus@paladininvesting.com'} target="_blank" className="blueText"> contactus@paladininvesting.com.</Link></p> */}



                        </div>
                        <div className='mt-40'>
                            <PaiverseCommunity />
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
        </>
    )
}

export default PrivacyPolicy