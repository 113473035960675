import React from "react";
import ItemContent from "../components/layouts/Airdrop/ItemContent";

const Airdrop = () => {
  return (
    <div className="home-8 airdrop">
       <ItemContent />
    </div>
  );
};

export default Airdrop;
