import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import { s3Url } from "../store/baseUrl";
const Favorites = () => {
  LoadingOverlay.propTypes = undefined
  let [releaseBottles, setReleaseBottles] = useState([]);
  let [skip, setSkip] = React.useState(0);
  let [limit, setLimit] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    releaseBottles = [];
    skip = 0;
    limit = 8;
    setLimit(limit)
    setReleaseBottles(releaseBottles);
    setSkip(skip);
    getFavList();
    return () => {
      releaseBottles = [];
      skip = 0;
      setSkip(skip);
      limit = 8;
      setLimit(limit)
      setReleaseBottles(releaseBottles);
      // setShowModal(false);
      setVisible(false);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery === "") {
      releaseBottles = [];
      skip = 0;
      setReleaseBottles(releaseBottles);
      setSkip(skip);
      limit = 8;
      setLimit(limit)
      getFavList();
    }

    return () => {
      setReleaseBottles([]);
      setSkip(0);
      limit = 8;
      setLimit(limit)
      // setShowModal(false);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const getFavList = () => {
    setLoading(true);
    const payload = {
      skip: 0,
      limit: limit,
    };
    axios
      .get(`api/v1/user/favouriteList`, {
        params: payload,
      })
      .then((response) => {
        let data = response.data.data
        // releaseBottles = [...releaseBottles, ...response.data.data];
        setReleaseBottles(data);
        if (data.rows.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const loadMore = () => {
    skip = skip + 8;
    setSkip(skip);
    limit = limit + 8;
    setLimit(limit)
    getFavList();
  };

  const bookMark = (id) => {
    if (globalState.isLoggedIn) {
      const payload = {
        productItemId: id,
      };
      axios
        .put(`/api/v1/liveStore/markFavourite`, payload)
        .then((response) => {
          const toasterError = {
            type: "success",
            message: "Removed from favorites",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getFavList();
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  }

  // claim NFT with main contract
  return (
    <LoadingOverlay active={loading} spinner={true} text="Loading...">
      <div className="favWrap">
        <section className="flat-title-page inner">
          <h1 className="heading text-white"> Favorites</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p> */}
        </section>
        <section className="tf-section wrap-accordion">
          <div className="themesflat-container">
            <div className="row">
              {releaseBottles.count && releaseBottles.rows.map((item, key) => (
                <div key={key} className="col-xl-3 col-sm-6 col-12">
                  <div className="sc-card-product height100">
                    <div className="card-media">
                      <Link to={`/marketPlace-item-details?id=${item.favProductItem?.id}`}>
                        <img
                          src={`${s3Url}icons/${item.favProductItem?.products.imageUrl}`}
                          alt="Axies"
                        />
                      </Link>
                      <span className="wishlist-button heart active" onClick={() =>
                        bookMark(item?.favProductItem?.id)}>

                      </span>
                    </div>
                    <div className="card-title d-flex  noWrap">
                      <div className="left">
                        <h3> <Link to={`/marketPlace-item-details?id=${item.favProductItem?.id}`}>
                          {item.favProductItem.products?.brand?.name} {item.favProductItem?.products.title}
                        </Link>
                        </h3>
                      </div>

                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!releaseBottles.count  ? <div className="noData">
                There are no NFTs
              </div>:'' }
            
            {visible && (
              <div className="d-flex justify-content-center">
                <Link
                  to="#"
                  className="exploreMore"
                  onClick={loadMore}
                >
                  <span>Load More</span>
                </Link>
              </div>

            )}


          </div>

        </section >
      </div>
    </LoadingOverlay>
  );


};

export default Favorites;
