import React, { useState, useEffect } from "react";
import axios from "../axiosinstance";
import { Link, useParams, useNavigate } from "react-router-dom";
import apiUrl from "../store/globalapiurl"
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import LoadingOverlay from "react-loading-overlay";
import { s3Url } from "../store/baseUrl";
import BrandLiveAuction from "./BrandLiveAuction";
const Brands = () => {
  LoadingOverlay.propTypes = undefined;
  let navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  const [brandData, setBrandData] = useState([]);
  const [backgroundImg, setBackgroundImg] = useState();
  const [alldata, setData] = useState([]);
  const id = localStorage.getItem("brandID") ? localStorage.getItem("brandID") : '';  
  useEffect(() => {      
    if (id) {    
      getBrandList(id);
      getReleasesList(id);     
    } else {
      navigate(`/`);
    }
  }, [id]);
  const getBrandList = (id) => {
    const payload = {};
    setLoading(true);
    axios
      .get(apiUrl._getBrandDetails + id, {
        params: payload,
      }).then((response) => {
        setBrandData(response.data.data);
        if (response.data.data.bannerImage) {
          let img = s3Url + "brands/" + response.data.data.bannerImage;
          setBackgroundImg(img.toString())
        }
        setLoading(false);
        setTimeout(() => {
          localStorage.removeItem("brandID")
        }, 3000);
        
      })
      .catch((error) => {

      });
  };
  const getReleasesList = (id) => {
    const payload = {
      skip: 0,
      limit: 100,
      isUpComing: 0,
      brandId: id
    };
    axios
      .get(apiUrl._liveStore, { params: payload }).then((res) => {
        let data = res.data.data;
        setData(data);
        setLoading(false);
      })
      .catch((error) => {

      });
  };
  let inputStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage: `url(${backgroundImg})`,

  };

  return (
    <LoadingOverlay active={loading} spinner={true} text="Loading...">
    <div className="home-8 brands">
      <section className=' flat-title-page  inner' style={backgroundImg ? inputStyle : {}} >
        <h1 className="heading text-white">{brandData.name}</h1>
        <p>{brandData.bannerDescription}</p>
      </section>
      <section className='tf-section text-white'>
        <div className="themesflat-container">
          <div className="row ">
            <div className="col-md-4 col-lg-5 col-12">
              <div className="imgBox brandImg">
                {brandData.image ? <img src={`${s3Url}brands/${brandData.image}`} alt="brands" /> : ""}
              </div>
            </div>
            <div className="col-md-8 col-lg-7 col-12">
              <div className="brandInfotxt text-justify">
                <p>{brandData.description}</p>
              </div>
            </div>
          </div>
          <div className="tf-section pb-10">
            {alldata.count > 0 ? <BrandLiveAuction releaseData={alldata} /> : ''}
          </div>
        </div>
      </section>
      {brandData?.bannerImageTwo ? <img src={`${s3Url}brands/${brandData?.bannerImageTwo}`} alt="brands" /> : ""}
      {brandData.id === 1 ? <section className='faraday'>
        <div className="themesflat-container">
          <div className="faradayContent text-justify">
            <h2 className="heading text-center text-white mb-5">Faraday Still</h2>
            <p className="fst-italic">It was designed by Dr David Faraday, a distant relative of Victorian physicist, Michael Faraday.This magical process not only imbues our raw spirit with great complexity, depth and finesse, but also removes many of the undesirable chemical compounds - something that a conventional pot still system cannot achieve. Arriving at the spirit safe at an industry high of 92% abv, our spirit is virtually free from these chemical compounds and this becomes crucial during cask ageing. Scotland can't use this a single column still as their Geographical Indicators state that 2 pot stills must be used. </p>
          </div>
        </div>
      </section> : <div style={{ paddingTop: "50px" }}></div>}

      <div className="brandVideo">
        {brandData.video ? <video autoPlay loop playsInline muted src={`${s3Url}brands/${brandData.video}`} /> : ''}
      </div>
      <div className="awardsWrap mg-t-40">
        {brandData.awardImage ? <img src={`${s3Url}brands/${brandData.awardImage}`} alt="axies" /> : ""}
      </div>

      <div className="darkGray tf-section">
        <PaiverseCommunity />
      </div>

    </div>
    </LoadingOverlay>

  );
};

export default Brands;
