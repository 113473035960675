import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../services/contants";
import axios from "./../axiosinstance";
import Context from "../store/context";
import Countdown from "react-countdown"
import verifyCheck from "../assets/images/icon/verifyCheck.png";
const ForgotPassword = (props) => {
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  let navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [resendEmail, setResendEmail] = useState()
  const [formErrors, setFormErrors] = useState({});
  const [isActive, setActive] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [sec, setSec] = useState(60);
  const [resendTime, setResendTime] = useState();
  const [verificationPage, setVerificationPage] = useState("forgot-password");
  React.useEffect(() => {

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      forgotPasswordSubmit();
    }
  }, [formErrors]);

  const forgotPasswordSubmit = () => {
    const payload = {
      email: formValues.email,
    };
    axios
      .put(`api/v1/user/forgotPassword`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setResendEmail(formValues.email);
          time(60);
          setResendTime(1)
          setVerificationPage("verifyEmail")
        }
      })
      .catch((error) => {
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  };

  const ShowError = (values) => {
    if (isSubmit) {
      let error = "";
      if (!values.email) {
        error = "Email is required.";
      } else if (!EMAIL_REGEX.test(values.email)) {
        error = "Please enter valid email address.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.replace(/\s/g, "").length || value == "")
      setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Please enter valid email address.";
    }
    return errors;
  };

  const time = (count) => {
    let counter = count;
    setSec(counter)
    setInterval(() => {
      counter--;
      if (counter === 0) {
        setActive(true);
        return false;
      } else {
        if (counter >= 0) {
          setSec(counter);
        }
      }
    }, 1000);
  }
  const sendAgain = () => {
    const payload = {
      email: resendEmail,
    };
    axios
      .put(`api/v1/user/forgotPassword`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          let count = resendTime + 1;
          setResendTime(count);
          if (count === 3) {
            setShowResend(false)
          } else {
            setActive(false);
            time(60);
          }
          const toasterError = {
            type: "success",
            message: "A link has been sent to your email address",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });

        }
      })
      .catch((error) => {
    
      });
  };

  return (
    <React.Fragment>
      <div>
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="page-title-heading mg-bt-12">
              <h1 className="heading text-center">
                {/* Forgot Password */}
                {verificationPage === "forgot-password"
                  ? "Forgot Password"
                  : "Reset Password"}
              </h1>
            </div>
          </div>
        </section>

        <section className="tf-login tf-section darkGray">
          <div className="themesflat-container">
            {verificationPage === "forgot-password" && (
              <div className="row">
                <div className="col-12">
                  <div className="flat-form box-login-email">
                    <div className="form-inner">
                      <form id="contactform" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            tabIndex="2"
                            autoFocus
                            type="text"
                            placeholder="Email Address"
                            value={formValues.email}
                            onChange={handleChange}
                          />
                          {ShowError(formValues)}
                        </div>
                        <div className="row-form style-1">
                          <label></label>
                          <Link
                            className="forgot-pass"
                            to=""
                            onClick={() => props.changeType("login")}
                          >
                            Login?
                          </Link>
                        </div>

                        <button className="submit">Submit</button>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            )}
            {verificationPage === "verifyEmail" && (<>
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                <img src={verifyCheck} />
              </h2>
              <h5 className="sub-title help-center mg-bt-32 ">
               A link has been sent to your email address, Please click on it to reset your password. In case the email is not in the Inbox please check your Junk folder. 
              </h5>
              {showResend && (<div className="sub-title help-center countdown">
                <span>Didn't get a Email?</span>
                {isActive ? <>
                  <button className="resendEmailBtn" onClick={sendAgain}>Resend</button> </> :
                  <span className="timerWrap">00:00:{sec > 9 ? sec : '0' + sec}</span>}
              </div>)}


            </>
            )}
          </div>

        </section>



      </div>
    </React.Fragment>
  );
};
export default ForgotPassword;
