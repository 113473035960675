import React from "react";
import verifyCheck from "../assets/images/icon/verifyCheck.png";
const VerifyEmailOrPassword = (props) => {

  const { type } = props;
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      {type != "email" && (
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">{"Reset Password"}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      
      <section className="tf-help-center tf-section darkGray">
        <div className="themesflat-container">
        
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                <img src={verifyCheck} />
              </h2>
              {/* <div>
                <img src={verifyCheck} />
              </div> */}
              <h5 className="sub-title help-center mg-bt-32 ">
                {props.errorDescription
                  ? props.errorDescription
                  : "A link has been sent to your email address, Please click on it to reset your password. In case the email is not in the Inbox please check your Junk folder."}
              </h5>
              
              
           
        </div>
      </section>
    </React.Fragment>
  );
};
export default VerifyEmailOrPassword;
