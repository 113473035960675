import React, { useRef, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import apiUrl from "../store/globalapiurl"
import axios from "../axiosinstance";
import * as moment from 'moment'
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import { ethers } from "ethers";
import { getContract, ipfsUrl } from "../Helpers/contract";
import { s3Url } from "../store/baseUrl";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import Web3 from 'web3';
import Web3Provider from 'react-web3-provider';

import { NORAMP_URL, NORAMP_APP_ID } from '../services/contants';

const ReleaseDetails = () => {
  LoadingOverlay.propTypes = undefined
  const loginUser = localStorage.getItem("email") || '';
  let navigate = useNavigate();
  const location = useLocation();
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  let [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [showMint, setShowMint] = React.useState(false);
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  const [alldata, setData] = useState([]);
  const [showTimer, setShowTimer] = useState();
  const [backgroundImg, setBackgroundImg] = useState();
  let walletType;
  let walletAddress;
  let deadline;
  let estDeadline;
  let count = 60;
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [priceId, setPriceId] = useState(null);

  const { id } = useParams();
  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };
  React.useEffect(async () => {
    getDetails();
    ethToDollar();
    // setServerTime(globalState.serverTime);  
  }, []);
  
  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price
          localStorage.setItem("priceInUSD", usdPrice);
        }

      })
      .catch((error) => {
      });
  };
  const getTimeUntil = (deadline) => {
    let date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
    let newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    let deadlineDate = moment(deadline).format('YYYY-MM-DD HH:mm:ss');
    let time = Date.parse(deadlineDate) - Date.parse(newDate);
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      setShowMint(true)
    } else {
      setShowMint(false)
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };
  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  useEffect(() => {
    setInterval(() => {
      count = count - 1;
      getTimeUntil(deadline);
    }, 1000);

    return () => getTimeUntil(deadline);
  }, [deadline]);


  const getDetails = () => {
    const payload = {};
    if (loginUser) {
      payload['email'] = loginUser
    }
    axios
      .get(apiUrl._liveStoreDetails + id, {
        params: payload,
      }).then((res) => {
        let data = res.data.data;
        setData(data);
        estDeadline = res.data.data.estTime;
        if (res.data.data.fieldImageUrl) {
          let img = s3Url + "icons/" + res.data.data.fieldImageUrl;
          setBackgroundImg(img.toString())
        }

        if (res.data.data.whitelist === 1) {
          let finalDate = moment(res.data.data.firstReleaseDate).subtract(3, 'day').format("YYYY-MM-DD HH:mm:ss");
          deadline = finalDate;
        } else {
          deadline = moment(res.data.data.firstReleaseDate).format("YYYY-MM-DD HH:mm:ss");
        }
        setTimeout(function () {
          setLoading(false);
          setShowTimer(res.data.data.firstReleaseDate)
        }, 1000)

      })
      .catch((error) => {

      });
  };
  const getWallet = () => {
    return new Promise((wallet, reject) => {
      axios
        .get(`api/v1/user/connectUserWallet`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            walletType = response.data.data.walletType;
            if (walletType) {
              walletAddress = response.data.data.walletId;
              wallet(true)
            } else {
              const toasterError = {
                type: "error",
                message: "Please connect the wallet",
              };
              globalDispatch({ type: "TOASTER", state: toasterError });
              navigate(`/wallet-connect`);
            }

          }
        })
        .catch((error) => {
          reject(error)
        });
    })
  };
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const mintNFT = async (item) => {
    if (globalState.isLoggedIn) {
      const wallet = await getWallet();
      if (wallet) {
        const res = walletType === "Coinbase" ? await connectCoinbase() : await connectMetamask();

        if (walletAddress === res.account.toString().toLowerCase()) {
          connectWalletHandler(item, res.account, res.library);
        } else {
          setLoading(false);
          const toasterError = {
            type: "error",
            message: "Please use the wallet you have connected to your Paiverse account",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });

        }
      }
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };

  const connectWalletHandler = async (item, account, library) => {
    try {
      setLoading(true);
      setWeb3Account(account);
      setWeb3Library(library);
      const resolve = await saveWalletDetails(walletType, account);
      if (resolve) {
        const payload = {
          buyerAddress: account
        }
        axios
          .get(`api/v1/liveStore/priceAndToken/${id}?buyType=BUY&paymentMethod=ETH`, {
            params: payload,
          })
          .then((response) => {
            if (response.data.statusCode == 200) {
              redeemNFT(library, account, item, response.data.data.token, response.data.data.price.toString(), response.data.data.voucher, response.data.data.ethPrice);

            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    } catch (e) {

      setLoading(false);
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {

          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {

          reject(error);
        });
    });
  };
  // mint the NFT with main contract
  const redeemNFT = async (library, account, item, token, price, voucher, ethPrice1) => {
    try {
      const ethPrice = voucher.voucher.minPrice
      let voucherData = {
        "nonce": voucher.voucher.nonce,
        "minPrice": ethPrice,
        "uri": voucher.voucher.uri,
        "signature": voucher.sign
      }

      const myContract = getContract(library, account);
      const response = await myContract.safeMint(account, voucherData, { value: ethPrice.toString() });
      const receipt = await response.wait();
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      const gasFee = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.gasUsed["_hex"], 18)
        ) *
        10 ** 18
      );

      payment(receipt, price, receiptTokenId, id, token, gasFee, ethPrice1);
    } catch (ex) {
      console.log("ex", ex)
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        console.log("ex====", ex);
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };


  // store the blockchain transaction
  const payment = (paymentData, dollarPrice, token, id, oldNFTToken, gasFee, ethPrice) => {
    setLoading(true);
    const payload = {
      token: oldNFTToken,
      receiptTokenId: token,
      id: id,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: paymentData.from,
      currency: "ETH",
      to: paymentData.to,
      gasAmount: gasFee ? gasFee : 2300,
      conversionAmount: dollarPrice,
      ethPrice: ethPrice
    };
    axios
      .post(`api/v1/liveStore/payment`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          const toasterError = {
            type: "success",
            message: `${capitalizeFirstLetter(alldata.title)}  minted sucessfully`,
          };
          setLoading(false);
          globalDispatch({ type: "TOASTER", state: toasterError });
          setShowModal(true);
          setLoading(false);
          navigate("/my-collection");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const releaseDate = (whitelist) => {
    let relDate = alldata.firstReleaseDate
    if (whitelist === 1) {
      let finalDate = moment(relDate).subtract(3, 'day').format("MMM, DD YYYY") + ' at ' + moment(relDate).format("hh:mm A") + ' EST';
      return finalDate
    } else {
      let finalDate = moment(relDate).format("MMM, DD YYYY") + ' at ' + moment(relDate).format("hh:mm A") + ' EST';
      return finalDate
    }
  }
  let inputStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage: `url(${backgroundImg})`,

  };

  const getPriceIdNoRamp = () => {
    if (globalState.isLoggedIn) {
      setLoading(true);

      const payload = {
        buyType: "BUY",
        buyerAddress: "",
        paymentMethod: "NORAMP"
      };

      axios
        .get(`api/v1/liveStore/priceAndToken/${id}`, {params: payload})
        .then((response) => {
          if (response.data.statusCode == 200) {
            setLoading(false);
            setPriceId(response?.data?.data?.priceId?.data?.id);
          }
        })
        .catch((error) => {
          setLoading(false);
          globalDispatch({ type: "TOASTER", state: {
            type: "error",
            message: error?.response?.data?.message
          } });
        });
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };

   useEffect(() => {
    window.addEventListener('message', (event) => {

      if (event.data.event === 'noramp:onPayment') {
        const type = event.data?.detail?.type;
        const status = event.data?.detail?.data?.status;
        
        if (type === 'finished' && status === 'paid') {
          setPriceId('');
          setLoading(true);
          globalDispatch({ type: "TOASTER", state: {
            type: "success",
            message: "We are processing your request. You'll receive email from our side once minting is done."
          } });
          setTimeout(() => {
            navigate("/my-collection");
          }, 2000);
        }
      }

      if (event.data.event === 'noramp:close') {
        setPriceId('');
      }
    });
  }, []);

  return (
    <div className='ProductDetails withoutHeader'>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <section className='scotchWhiskey '>
          <div className="themesflat-container">
            <div className="row">
              <div className="grid-1 col-md-6 col-lg-5 ">
                <div className="productScotch">
                  <h2 className="heading">{alldata.brandName} <br/>{alldata.title}<br />{alldata.tagline1}</h2>
                  <p>{alldata.tagline2} </p>
                  <p> Blockchain Address <br /> <span ><a className="purple blockchainKey" target=" _blank" href="https://etherscan.io/token/0x5f581b3f61be3ee721533be96faf8c795236a1f2">
                  0x5F581b3F61bE3ee721533bE96fAF8c795236a1f2</a></span> </p>
                  <p> Blockchain  <br /> <span className="purple">Ethereum</span> </p>

                  <div>
                  </div>

                  {!showMint && (<div>
                    {showTimer && (<><div className="countdownWrap">
                      <ul className="clockWrap">
                        <li className="Clock-days">{leading0(days)} Days</li>
                        <li className="Clock-hours">{leading0(hours)} Hours</li>
                        <li className="Clock-minutes">{leading0(minutes)} Minutes</li>
                        <li className="Clock-seconds">{leading0(seconds)} Seconds</li>
                      </ul>
                    </div>
                      <button className='mintButton roundBtn disableBtn'>
                        Mint
                      </button></>
                    )}
                  </div>)}

                  {showMint && (<button onClick={() => mintNFT(alldata)} className='mintButton roundBtn '>
                    Mint using ETH
                  </button>)}
                  {showMint && (<div className="mint-credit-card-btn" onClick={()=>getPriceIdNoRamp()}>
                    Mint using Card
                    {priceId && <iframe
                      className="noramp-iframe"
                      src={`${NORAMP_URL}/embed/payments/${NORAMP_APP_ID}?price_id=${priceId}&theme=dark`}
                      allow="clipboard-write"
                      height="100%"
                      width="100%"
                    />}
                  </div>)}
                </div>
              </div>
              <div className="grid-2 col-md-6 col-lg-4">
                <div className="productimg text-center">
                  {alldata.video ?
                    <div className="videoBox">
                      <div className="customLoader"></div>
                      <video autoPlay loop playsInline muted src={`${s3Url}icons/${alldata.video}`} />
                    </div>
                    :
                    <>
                      {alldata.imageUrl ? <img src={`${s3Url}icons/${alldata.imageUrl}`} alt="release" /> : ""}
                    </>

                  }

                </div>
              </div>
              <div className="grid-3 col-lg-3">
                <div className="productinfo">
                  <h4> Info </h4>
                  <ul>
                    {!showMint && (<li>
                      <span>
                        {alldata.whitelist === 1 ? ('Early Access On') : 'Live On'}
                      </span>
                      <p>{alldata.firstReleaseDate ? (releaseDate(alldata.whitelist)) : ''}</p>

                    </li>)}

                    <li>
                      <span>Release Type</span>
                      <p> Mint ({alldata.editionSize - alldata.inStock}/{alldata.editionSize})</p>

                    </li>
                    <li>
                      <span>Price </span>
                      {alldata.price ? <p> ${alldata.price} ({ETHtoUSDPrice(alldata.price)} ETH)</p> : ''}
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-6">
                          <span>Region </span>
                         <p>{alldata.region ? alldata.region:'' }</p>
                        </div>
                        <div className="col-6">
                          <span>Age </span>
                          <p>{alldata.age ? alldata.age:'' }</p>
                        </div>
                      </div>

                    </li>
                    <li>
                      <div className="row">
                        <div className="col-6">
                          <span>Cask </span>
                          <p>{alldata.cask ? alldata.cask:'' }</p>
                        </div>
                        <div className="col-6">
                          <span>Abv </span>
                          <p>{alldata.abv ? alldata.abv:'' }</p>
                        </div>
                      </div>

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='description tf-section'>
          <div className="themesflat-container">
            <div className='row'>
              <div className='col-md-7 order-2'>
                <div className='descriptionBox text-justify'>
                  <h3> Description</h3>
                  <h6> About </h6>
                  <p> {alldata.description}</p>
                </div>
              </div>
              <div className='col-md-5  order-1'>
                <div className='imageBox'>
                  {alldata.imageUrl ? <><img src={`${s3Url}icons/${alldata.imageUrl}`} alt="release" /></> : ""}

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='tastingNotes lightBlack tf-section'>
          <div className="themesflat-container">
            <div className='row justify-content-md-center'>
              <div className='col-md-6  col-lg-5 order-1'>
                <div className='imageBox'>
                  {alldata.imageUrl2
                    ? <img src={`${s3Url}icons/${alldata.imageUrl2}`} alt="release" />
                    :
                    <>
                      {alldata.imageUrl ? <><img src={`${s3Url}icons/${alldata.imageUrl}`} alt="release" />  </> : ""}</>

                  }
                </div>
              </div>
              <div className='col-md-6 col-lg-5 order-2'>
                <div className='tastingList'>
                  <h3> Tasting Notes </h3>
                  <p className="pb-24">{alldata.tastingNotes}</p>

                  <ul>
                    <li>
                      <span> Nose </span>
                      <p>{alldata.tastingNotesNose}</p>
                    </li>

                    <li>
                      <span> Taste </span>
                      <p>{alldata.tastingNotesTaste}</p>
                    </li>
                    <li>
                      <span> Finish </span>
                      <p>{alldata.tastingNotesFinish}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {alldata.experience 
          ? < section  className={id === "6" ? 'experienceCont' : 'experienceCont experienceBg'}>
            <div className="themesflat-container">
              <div className=" tf-section">
                <div className=' text-justify'>
                  <h3 className="heading text-center text-white"> The Experience </h3>
                  <p className='text-white'>{alldata.experience}</p>
                </div>
              </div>
            </div>
          </section>
          : ''
        }

        <div className="darkGray tf-section">
          {alldata.masterBlenderPhoto
            ?
            <div className='clintDetails '>
              <div className='clintImg text-center mb-5'>
                <img src={`${s3Url}icons/${alldata.masterBlenderPhoto}`} />
              </div>
              <div className='clintInfo  mg-bt-30 text-justify'>
                <p className='text-white'>{alldata.masterBlenderText}</p>
              </div>
            </div>
            :
            ""
          }
          {alldata.lableOfField
          // ${s3Url}icons/${alldata.fieldImageUrl}
          ? < section className='experienceCont lableOfField' style={backgroundImg ? inputStyle : {}} >
            <div className="themesflat-container">
              <div className=" tf-section">
                <div className=' text-justify'>
                  <h3 className="heading text-center text-white"> {alldata.lableOfField} </h3>
                  <p className='text-white'>{alldata.fieldDescription}</p>
                </div>
              </div>
            </div>
          </section>
          : ''
        }
          <div className='mt-40'>
            <PaiverseCommunity />
          </div>
        </div>

      </LoadingOverlay>
    </div >
  )
}

export default ReleaseDetails;