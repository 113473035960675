import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap-accordion";
import moment from 'moment/moment';

import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import facebook from "./../../src/assets/images/icon/ic_facebook.svg"
import instagram from "./../../src/assets/images/icon/ic_instagram.svg"
import linkdien from "./../../src/assets/images/icon/ic_linkdien.svg"
import telegram from "./../../src/assets/images/icon/ic_telegram.svg"
import youtube from "./../../src/assets/images/icon/ic_you_tube.svg"
import twitter from "./../../src/assets/images/icon/ic_twitter.svg"
import tikTok from "./../../src/assets/images/icon/IC_tik_tok.svg"


const MarketFaq = ({ prodId }) => {
    LoadingOverlay.propTypes = undefined
    const location = useLocation();
    let [releaseBottles, setReleaseBottles] = useState([]);
    const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
    const [bottleId, setBottleId] = useState();
    let [loading, setLoading] = useState(true);
    let [accordionHight, setAccordionHight] = useState(false);

    React.useEffect(() => {

        if (prodId) {
            const [a, id] = location.search.split("?id=");
            setBottleId(id);
            getReleaseBottelsDetails(id);
        }else{
            const [a, id] = location.search.split("?id=");
            setBottleId(id);
            getReleaseBottelsDetails(id);  
        }

    }, [prodId]);
    const getReleaseBottelsDetails = (id) => {
        axios
            .get(`api/v1/marketPlace/${id}`)
            .then((response) => {
                setLoading(false);
                setReleaseBottleDetails(response.data.data);
                setAccordionHight(true)
            })
            .catch((error) => {

            });
    };
    let brandTitle = (title) => {
        let titleTxt = "About " + title;
        return titleTxt;

    };

    return (
        <>
         <LoadingOverlay active={loading} spinner={true} text="Loading...">
            <div className="flat-accordion2">
                <Accordion title="Description" show={accordionHight}>
                    <p>{releaseBottleDetail?.product?.description}</p>

                </Accordion>
                <Accordion title="Properties" show={accordionHight}>
                    {releaseBottleDetail?.product?.region && (<p><strong>Region:</strong> {releaseBottleDetail?.product?.region} </p>)}
                    {releaseBottleDetail?.product?.age && (<p><strong>Age:  </strong>{releaseBottleDetail?.product?.age} </p>)}
                    {releaseBottleDetail?.product?.cask && (<p><strong>Cask: </strong> {releaseBottleDetail?.product?.cask} </p>)}
                    {releaseBottleDetail?.product?.abv && (<p><strong>Abv: </strong> {releaseBottleDetail?.product?.abv} </p>)}

                    {releaseBottleDetail?.product?.tastingNotesFinish && (<p><strong>Finish: </strong> {releaseBottleDetail?.product?.tastingNotesFinish} </p>)}
                    {releaseBottleDetail?.product?.tastingNotesTaste && (<p><strong>Taste: </strong> {releaseBottleDetail?.product?.tastingNotesTaste} </p>)}
                    {releaseBottleDetail?.product?.tastingNotesNose && (<p><strong>Nose: </strong> {releaseBottleDetail?.product?.tastingNotesNose} </p>)}

                </Accordion>
                <Accordion title="Details" show={accordionHight}>
                    <p><strong>Contract Address:</strong> 0x5F581b3F61bE3ee721533bE96fAF8c795236a1f2</p>
                    <p><strong>Token Id:</strong> {releaseBottleDetail?.blockChainNFTId}</p>
                    <p><strong>Token Standard:</strong> ERC-1155</p>
                    <p><strong>Chain:</strong> Ethereum</p>
                    <p><strong>Last Updated:</strong> {moment(releaseBottleDetail?.updatedAt).format('llll')}</p>
                    <p><strong>Created Earning:</strong> 85%</p>

                </Accordion>
                <Accordion title={brandTitle(releaseBottleDetail?.product?.brand?.name)} show={accordionHight}>
                    <p>{releaseBottleDetail?.product?.brand.description}</p>

                    <div className="social-media">

                        {releaseBottleDetail?.product?.brand.instagram
                            && (<a href={releaseBottleDetail?.product?.brand?.instagram
                            } rel="home" className="socialLogo" target="_blank">
                                <img src={instagram} alt="nft-gaming" />
                            </a>)}

                        {releaseBottleDetail?.product?.brand.facebook && (<a href={releaseBottleDetail?.product?.brand.facebook} rel="home" className="socialLogo" target="_blank"><img src={facebook} alt="nft-gaming" />
                        </a>)}

                        {releaseBottleDetail?.product?.brand.twitter && (<a href={releaseBottleDetail?.product?.brand.twitter} rel="home" className="socialLogo" target="_blank"> <img src={twitter} alt="nft-gaming" />
                        </a>)}

                        {releaseBottleDetail?.product?.brand.youtube && (<a href={releaseBottleDetail?.product?.brand.youtube} rel="home" className="socialLogo" target="_blank"> <img src={youtube} alt="nft-gaming" />
                        </a>)}
                        <a href="https://www.youtube.com/user/penderynwhisky" rel="home" className="socialLogo" target="_blank">
                        </a>
                    </div>
                </Accordion>
            </div>
            </LoadingOverlay>
        </>
    )
}

export default MarketFaq