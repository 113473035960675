import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../axiosinstance";
import { ethers } from "ethers";
import { connectMetamask, connectCoinbase, } from "../../../Helpers/contractMethods";
import { getContract } from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import { s3Url } from "../../../store/baseUrl";
const ItemContent = () => {
  LoadingOverlay.propTypes = undefined
  let [releaseBottles, setReleaseBottles] = useState([]);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  let walletType;
  let walletAddress;

  React.useEffect(() => {
    setLoading(true);
    releaseBottles = [];
    skip = 0;
    setReleaseBottles(releaseBottles);
    setSkip(skip);
    getAirdropBottels();

    return () => {
      releaseBottles = [];
      skip = 0;
      setSkip(skip);
      setReleaseBottles(releaseBottles);
      // setShowModal(false);
      setVisible(false);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery === "") {
      releaseBottles = [];
      skip = 0;
      setReleaseBottles(releaseBottles);
      setSkip(skip);
      getAirdropBottels();
    }

    return () => {
      setReleaseBottles([]);
      setSkip(0);
      // setShowModal(false);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const getAirdropBottels = () => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      listed: 0,
      skip: skip,
      limit: 9,
      buyType: "MINT",
    };
    axios
      .get(`api/v1/user/owned`, {
        params: payload,
      })
      .then((response) => {
        releaseBottles = [...releaseBottles, ...response.data.data.rows];
        setReleaseBottles(releaseBottles);
        if (releaseBottles.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setLoading(false);
      })
      .catch((error) => {
      });
  };


  const loadMore = () => {
    skip = skip + 9;
    setSkip(skip);
    getAirdropBottels();
  };

  const connectWalletHandler = async (item, account, library) => {
    setLoading(true);
    try {
      setWeb3Account(account);
      setWeb3Library(library);
      const resolve = await saveWalletDetails(walletType, account);
      if (resolve) {
        const payload = {
          buyerAddress: account,
          productItemId: Number(item.id),
          buyType: "AIRDROP"
        }
        axios
          .get(`api/v1/liveStore/priceAndToken/${item.productId}`, { params: payload })
          .then((response) => {
            if (response.data.statusCode === 200) {
              console.log(response.data);
              redeemNFT(library, account, item, item.blockChainNFTId, response.data.data);

            }
          })
          .catch((error) => {
            setLoading(false);
          });


        // 
      }
    } catch (e) {
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getWallet = () => {
    return new Promise((wallet, reject) => {
      axios
        .get(`api/v1/user/connectUserWallet`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            walletType = response.data.data.walletType;
            if (walletType) {
              walletAddress = response.data.data.walletId.toString();
              wallet(true)
            } else {
              const toasterError = {
                type: "error",
                message: "Please connect the wallet",
              };
              globalDispatch({ type: "TOASTER", state: toasterError });
              navigate(`/wallet-connect`);
            }
          }
        })
        .catch((error) => {
          reject(error)
        });
    })
  };
  const mintNFT = async (id) => {
    if (globalState.isLoggedIn) {
      const wallet = await getWallet();
      if (wallet) {
        const res = walletType === "Coinbase" ? await connectCoinbase() : await connectMetamask();
        if (walletAddress === res.account.toString().toLowerCase()) {
          connectWalletHandler(id, res.account, res.library);
        } else {
          setLoading(false);
          const toasterError = {
            type: "error",
            message: "Please use the wallet you have connected to your Paiverse account",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });

        }
      }

    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };

  // save blockchain transaction
  const payment = (paymentData, dollarPrice, token, item, oldNFTToken, gasFee) => {
    const payload = {
      token: oldNFTToken,
      receiptTokenId: token,
      id: item.productId,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: paymentData.from,
      currency: "ETH",
      to: paymentData.to,
      gasAmount: gasFee ? gasFee : 2300,
      conversionAmount: dollarPrice,
    };
    axios
      .post(`api/v1/liveStore/redeem`, payload)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const toasterError = {
            type: "success",
            message: "Claimed Successfully",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          item.isMinted = 1;
          releaseBottles = [];
          setReleaseBottles(releaseBottles);
          getAirdropBottels();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // claim NFT with main contract
  const redeemNFT = async (library, account, item, token, backendSign) => {
    setLoading(true);
    try {
      let voucherData = {
        "nonce": backendSign.voucher.voucher.nonce,
        "minPrice": backendSign.voucher.voucher.minPrice,
        "uri": backendSign.voucher.voucher.uri,
        "signature": backendSign.voucher.sign
      };

      const myContract = getContract(library, account);
      const response = await myContract.safeMint(account, voucherData, { value: backendSign.voucher.voucher.minPrice });
      const receipt = await response.wait();
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      const gasFee = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.gasUsed["_hex"], 18)
        ) *
        10 ** 18
      );

      setLoading(false);
      payment(receipt, item.sellingAmount, receiptTokenId, item, token, gasFee);
    } catch (ex) {

      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };
  const release = () => {
    return (
      <div className="airDropWrap">
        <div className="row">
          {releaseBottles.map((item, key) => (
            <div key={key} className="col-xl-3 col-sm-6 col-12">
              <div className="sc-card-product">
                <Link className="card-media" to={`/airdropitem-details?id=${item.id}`}>
                  <img
                    src={`${s3Url}icons/${item.product.imageUrl}`}
                    alt="Axies"
                  />
                </Link>

                <div className="card-title d-flex  noWrap">
                  <div className="left">
                    <h3>
                      <Link to={`/airdropitem-details?id=${item.id}`}>
                        {item.product.title}
                      </Link>
                    </h3>
                  </div>
                  {item.isMinted === 0 ?
                    <div className="button-place-bid">
                      <button
                        onClick={() => mintNFT(item)}
                        disabled={item.isMinted > 0}
                        className={`tags ${item.isMinted > 0 && `color-red`
                          }`}
                      >
                        <span>Claim</span>
                      </button>
                    </div>
                    : <p className="color-red">Claimed</p>}
                </div>
              </div>
            </div>
          ))}
          {visible && (
            <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
              <Link
                to="#"
                className="sc-button loadmore fl-button pri-3"
                onClick={loadMore}
              >
                <span>Load More</span>
              </Link>
            </div>
          )}
        </div>
        {/* <Tabs className="myCollectionTab">
          <TabList>
            {airDropTab.map((data) => (
              <Tab key={data.id}>{data.title}</Tab>
            ))}
          </TabList>
          <TabPanel>           
            <div className="mycollactionHTML collection">
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mycollactionHTML">
              MarketPlaceListing
            </div>
          </TabPanel>
        </Tabs> */}
      </div>


    );
  };

  return (
    <Fragment>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div
          className={`flat-tabs items release-item w-100 ${!releaseBottles?.length && `release-height`
            }`}
        >
          <div className="option d-block">
            {releaseBottles.length > 0 && release()}
          </div>
          {releaseBottles && !releaseBottles?.length && !loading && (

            <div className="noData">
              No data.
            </div>
          )}
        </div>
      </LoadingOverlay>
    </Fragment>
  );
};

export default ItemContent;
