import React from "react";
import {s3Url} from "../../../store/baseUrl";
import videoMp4 from '../../../assets/images/slider/bannerVideo.mp4'
const BannerVideo = ({ videoPath }) => {
    const Video = () => {
        return (
            <div className="videoWrap">
                {videoPath ?
                 <video autoPlay loop playsInline muted src={`${s3Url}icons/${videoPath}`} />
                 :
                 <video autoPlay loop playsInline muted src={videoMp4} />
                }
               
            </div>
        );
    }; 
    return (
        <Video />
    );
};

export default BannerVideo;
