import React, { useState, Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import CardModal from "../CardModal";
import apiUrl from "../../../store/globalapiurl"
import axios from "../../../axiosinstance";
import { s3Url } from "../../../store/baseUrl";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const HomeBrand = () => {
    let navigate = useNavigate();
    const [data, setData] = useState([])
    const [modalShow, setModalShow] = useState(false);
    useEffect(async () => {
        getBrandList();
    }, []);


    function handleOnClick(id, name) {
        let str = name;
        str = str.replace(/\s+/g, '-').toLowerCase();
        localStorage.setItem("brandID", id)
        navigate(`/brands/${str}`);
    }
    const getBrandList = () => {
        const payload = {
            skip: 0,
            limit: 100,
        };
        axios
            .get(apiUrl._getBrand, {
                params: payload,
            }).then((res) => {
                let resposne = res.data.data;
                setData(resposne)

            })
            .catch((error) => {
            });
    };


    return (
        <Fragment>
            <section className="tf-section homeBrand">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-24 text-center">Brands</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <Swiper className={`${data.length < 4 ? 'centerAlign' : ''}`}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    1000: {
                                        slidesPerView: 4,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                loop={false}
                                autoplay={true}
                            >
                                {data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="swiper-container show-shadow carousel auctions">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div  onClick={() => handleOnClick(item.id, item.name)} className="slider-item">
                                                        <img src={`${s3Url}brands/${item.brandLogo}`} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};

export default HomeBrand;
