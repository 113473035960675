import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "../../../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import Airdrop from "../../../pages/Airdrop";
import MyOfferListing from "./myOffer";
import RedeemedEditions from "./redeemedEditions";
import MarketPlaceCollection from "./marketplace-collection"
import MyActiveity from "./MyActiveity";
import { s3Url } from "../../../store/baseUrl";
const ItemContent = () => {  
  LoadingOverlay.propTypes = undefined;
  let defaultIndex=0;
  // let [, setDefaultIndex] = useState();

  const [dataTab] = useState([
    {
      id: 1,
      title: "My Collection",
    },
    {
      id: 2,
      title: "My Offers",
    },
    {
      id: 3,
      title: "Redeemed Editions",
    },
    {
      id: 4,
      title: "Airdropped",
    },
    {
      id: 5,
      title: "My Activity",
    },
    {
      id: 5,
      title: "List on Marketplace",
    },
  ]);

  const [myCollectionTab] = useState([
    {
      id: 1,
      title: "Collection",
    },
    {
      id: 2,
      title: "Marketplace",
    },
  ]);

  let [collectionBottles, setCollectionBottles] = React.useState([]);
  let [marketPlaceBottles, setMarketPlaceBottles] = React.useState([]);
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [a, tabIndex] = location.search.split("?myactiveity=");
    defaultIndex= tabIndex ? Number(tabIndex):0 ;
  // let defaultIndex= 0;
  React.useEffect(() => {
    setLoading(true);
    collectionBottles = [];
    skip = 0;
    setCollectionBottles(collectionBottles);
    setSkip(skip);
    getMyCollection();
    return () => {
      collectionBottles = [];
      skip = 0;
      setCollectionBottles(collectionBottles);
      setSkip(skip);
      setVisible(false);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery == "") {
      collectionBottles = [];
      skip = 0;
      setCollectionBottles(collectionBottles);
      setSkip(skip);
      getMyCollection();
    }

    return () => {
      setSkip(0);
      setCollectionBottles([]);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const loadMore = () => {
    skip = skip + 8;
    setSkip(skip);
    getMyCollection();
  };

  const getMyCollection = () => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      listed: 0,
      skip: skip,
      limit: 8,
      buyType: "BUY",
    };
    axios
      .get(`api/v1/user/owned`, {
        params: payload,
      })
      .then((response) => {
        collectionBottles = [...collectionBottles, ...response.data.data.rows];
        setCollectionBottles(collectionBottles);
        if (collectionBottles.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          localStorage.clear();
          navigate(`/login`);
        }
      });
  };
 
  const myCollectionTabs = () => {
    return (
      <Tabs defaultIndex={defaultIndex} >
        <TabList>
          <div className="themesflat-container">
            {dataTab.map((data, key) => (
              <Tab  key={key}>{data.title}</Tab>
            ))}
          </div>
        </TabList>
        <TabPanel>
          <div className="themesflat-container">
            {collectionBottles?.length > 0 && myCollection()}
            {collectionBottles && !collectionBottles?.length && !loading && (
              <div className="noData">
                There are no NFTs
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <MyOfferListing />
        </TabPanel>
        <TabPanel>
          <RedeemedEditions />

        </TabPanel>
        <TabPanel>
          <div className="themesflat-container">
            <Airdrop />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="myActivity">
            <MyActiveity />

          </div>
        </TabPanel>
        <TabPanel>
          <div className="themesflat-container">
            <MarketPlaceCollection />
          </div>
        </TabPanel>
      </Tabs>
    );
  };

  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const myCollection = () => {
    return (
      <div className="mycollactionHTML collection">
        <div className="row">
          {collectionBottles.map((item, key) => (
            <div key={key} className="col-xl-3 col-sm-6 col-12">
              <div className="sc-card-product">
                <div className="meta-info style">
                  <button className="wishlist-button heart wishlist-button-release"></button>
                </div>
                <div className="card-media">
                  <Link to={`/item-details?id=${item.id}`}>
                    <img
                      src={`${s3Url}icons/${item.product.imageUrl}`}
                      alt="Axies"
                    />
                  </Link>
                </div>
                <div className="card-title">
                  <h3>
                    <Link to={`/item-details?id=${item.id}`}>
                      {item.product.brandName} {item.product.title}
                    </Link>
                  </h3>
                  {/* <div className="price">
                    Price {item.product.price} USD  <span>({ETHtoUSDPrice(item.product.price)} ETH){" "}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>

          ))}

          {visible && (
            <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
              <Link
                to="#"
                className="sc-button loadmore fl-button pri-3"
                onClick={loadMore}
              >
                <span>Load More</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className={`flat-tabs items w-100`}>
          <div className="option d-block">{myCollectionTabs()}</div>
        </div>
      </LoadingOverlay>
    </Fragment>
  );
};

export default ItemContent;
