import React from "react";
import { Modal } from "react-bootstrap";
const SuccessModal = (props) => {

  return (
    // <Modal show={props.show} onHide={props.onHide}>
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton ></Modal.Header>
      <div className="modal-body space-y-20 pd-40 pt-0">
        <h3 className="text-center">Successfully Purchased</h3>
        {/* <p className="text-center">
          your bid <span className="price color-popup">(4ETH) </span> has been
          listing to our database
        </p> */}
        <p className="text-center">
          Your purchase was successful. Please click on My Collection below to
          view your purchased product
        </p>
        <button className="btn btn-primary"  onClick={() => props.redirect("/my-collection")}   > VIEW MY COLLECTION</button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
