import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import WalletConnect from "./pages/WalletConnect";
import PageNoteFound from "./pages/PageNotFound";
import PrivateRoute from "./route/PrivateRoute";
import Login from "./pages/Login";
import PublicRoute from "./route/PublicRoute";
import React, { useContext, useRef } from "react";
import Context from "./store/context";
import SignUp from "./pages/SignUp";
import axios from "./axiosinstance";
import ToasterContainer from "./components/layouts/ToasterContainer";

function App() {
  const { commonGlobalState } = useContext(Context);
  const { globalState } = commonGlobalState;
  const childRef = useRef();

  React.useEffect(() => {
    ethToDollar();
    if (globalState.toastError?.message) {
      childRef.current.showToast(
        globalState.toastError.type,
        globalState.toastError.message
      );
    }
  }, [globalState.toastError]);

  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price;
          localStorage.setItem("priceInUSD", usdPrice);
        }
      })
      .catch((error) => {
      });
  };
  return (
    <div>
      <ToasterContainer ref={childRef} />
      <Routes>
        {routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
          />
        ))}
        <Route exact path="/wallet-connect" element={<PrivateRoute />}>
          <Route exact path="/wallet-connect" element={<WalletConnect />} />
        </Route>
        {globalState && globalState.isLoggedIn && (
          <Route exact path="/login" element={<PublicRoute />}>
            <Route exact path="/login" element={<Login />} />
          </Route>
        )}
        {globalState && globalState.isLoggedIn && (
          <Route exact path="/sign-up" element={<PublicRoute />}>
            <Route exact path="/sign-up" element={<SignUp />} />
          </Route>
        )}
        <Route path="*" element={<PageNoteFound />} />
      </Routes>
    </div>
  );
}

export default App;
