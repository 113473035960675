import React from "react";
import ItemContent from "../components/layouts/my-collection/ItemContent";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
const MyCollection = () => {
  return (
    <div className="home-8">
      <section className='flat-title-page inner'>
        <h1 className="heading text-white">My Collection</h1>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p> */}
      </section>
      <div className="darkGray">
        <section className="tf-section">
          <ItemContent />

          <div className='mt-40'>
            <PaiverseCommunity />
          </div>

        </section>
      </div>
    </div>
  );
};

export default MyCollection;
