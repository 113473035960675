import React, { useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "./../axiosinstance";
import ForgotPassword from "./ForgotPassword";
import Context from "../store/context";
import { EMAIL_REGEX } from "../services/contants";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";

const Login = () => {
  LoadingOverlay.propTypes = undefined
  let navigate = useNavigate();
  const location = useLocation();
  let [loading, setLoading] = useState(false);
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  const initialValues = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [pageType, setPageType] = useState("login");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  let [passwordShow, setPasswordShow] = useState(false);

  React.useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      loginSubmit();
    }
  }, [formErrors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.replace(/\s/g, "").length || value == "")
      setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const getData = (data) => {
    const initialValues = {
      email: "",
      password: "",
    };
    setIsSubmit(false);
    setFormValues(initialValues);
    setPageType(data);
  };

  const validate = (values) => {
    const errors = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter valid email address.";
    }
    if (!values.password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const toggleCheckbox = (item) => {
    item = !item;
    setPasswordShow(item);
  };

  const ShowError = (values) => {
    if (isSubmit) {
      let error = "";
      if (!values.email) {
        error = "Email is required";
      } else if (!EMAIL_REGEX.test(values.email)) {
        error = "Please enter valid email address.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const PasswordError = (values) => {
    if (isSubmit) {
      let error = "";
      if (!values.password) {
        error = "Password is required.";
      }
      return <p className="error">{error}</p>;
    }
  };
  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price
          localStorage.setItem("priceInUSD", usdPrice);
        }
      })
      .catch((error) => {

      });
  };

  const loginSubmit = () => {
    setLoading(true);
    const body = document.getElementsByTagName("body")[0];
    body?.classList.add("fixed-loader");
    const payload = {
      email: formValues.email,
      password: formValues.password,
      platformType: "WEB",
      deviceToken: "1234567",
    }; 
    axios
      .post(`api/v1/user/login`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          // childRef.current.showToast("success", "Login successful");
          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("userName", response.data.data.userName);
          localStorage.setItem("userID", response.data.data.id);
          localStorage.setItem("email", response.data.data.email);
          setTimeout(() => {
            body?.classList.remove("fixed-loader");
            setLoading(false);
            ethToDollar()
            navigate(location.state ? location.state : `/`);
          }, 1500); 
          globalDispatch({ type: "LOGIN" });
        }
      })
      .catch((error) => {

        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        body?.classList.remove("fixed-loader");
        setLoading(false);
      });
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        {pageType === "login" && (
          <React.Fragment>
            <section className="flat-title-page inner">
              <div className="overlay"></div>
              <div className="themesflat-container">

                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">
                    {/* {pageType === "login" ? "Login" : "Forgot Password"} */}
                    Login
                  </h1>
                  {/* <p>Login is currently disabled, please come back again</p> */}
                </div>                

              </div>
            </section>
            <section className="tf-login tf-section darkGray">
              <div className="themesflat-container">
                <div className="row">
                  <div className="col-12">
                 
                    <div className="flat-form box-login-email">
                      {/* <div className="box-title-login">
                    <h5>login with email</h5>
                  </div> */}

                      <div className="form-inner">
                      <form id="contactform" onSubmit={handleSubmit}>
                      
                          <div className="form-group">
                            <input
                              id="email"
                              name="email"
                              tabIndex="2"
                              autoFocus
                              value={formValues.email}
                              onChange={handleChange}
                              type="text" 
                              placeholder="Email Address"
                            />

                            {ShowError(formValues)}
                          </div>
                          <div className="form-group position-relative">
                            <input
                              id="pass"
                              name="password" 
                              tabIndex="3"
                              value={formValues.password}
                              onChange={handleChange}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Password"
                            />
                            {formValues.password && (
                              <i
                                className={
                                  passwordShow
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                                onClick={() => toggleCheckbox(passwordShow)}
                              ></i>
                            )}
                            {PasswordError(formValues)}
                          </div>
                          <div className="row-form style-1">
                            <label></label>
                            <a
                              className="forgot-pass pointer"
                              onClick={() => setPageType("forgot-password")}
                            >
                              Forgot Password?
                            </a>
                             
                          </div>
                          <button className="submit">Login</button>
                          <div className="text-center mt-3">
                            <a className="forgot-pass">
                              Don't have an account?
                            </a>
                            <Link
                              to="/sign-up"
                              className="forgot-pass create-account"
                            >
                              &nbsp;Click here
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
        {pageType === "forgot-password" && (
          <ForgotPassword changeType={getData} />
        )}
      </LoadingOverlay>
    </div>
  );
};

export default Login;
