import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BannerVideo from "../layouts/home-6/BannerVideo";
import { s3Url } from "../../store/baseUrl";
import apiUrl from "../../store/globalapiurl";
import * as moment from 'moment';
import SwiperCore, { Navigation, Scrollbar, A11y, Pagination, Mousewheel, Autoplay, } from "swiper";
import videoMp4 from '../../assets/images/slider/bannerVideo.mp4';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import axios from "../../axiosinstance";

SwiperCore.use([Autoplay]);

const SliderStyle2 = () => {
  let navigate = useNavigate();
  let [productID, setProductID] = useState();
  let [bannerData, setBannerData] = useState([]);
  const [showMint, setShowMint] = React.useState(false)
  let deadline = "2022-12-15 11:00:00"
  React.useEffect(() => {
    getList();
    ethToDollar();
    getBanner();
  }, []);
  const [data] = useState([{}]);
  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price
          localStorage.setItem("priceInUSD", usdPrice);
        }

      })
      .catch((error) => {
      });
  };

  // const gotoRelease = (url) => {
  //   window.location.replace('`https://dev-admin2.paiverse.io/banner-management');
  //   //navigate(`https://dev-admin2.paiverse.io/banner-management`);
  // }

  const getTimeUntil = (deadline) => {
    let date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
    let newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    let deadlineDate = moment(deadline).format('YYYY-MM-DD HH:mm:ss');
    let time = Date.parse(deadlineDate) - Date.parse(newDate);
    if (time < 0) {
      setShowMint(true)
    } else {
      setShowMint(false)
    }
  };
  const getList = () => {
    const payload = {
      skip: 0,
      limit: 2,
      isUpComing: 0
    };
    axios
      .get(apiUrl._liveStore, { params: payload }).then((res) => {
        let data = res.data.data.rows
        setProductID(data[0].id)
      })
      .catch((error) => {

      });
  };

  const getBanner = (sortDirection, orderby) => {
    const payload = {
      skip: 0,
      limit: 10,
    };
    axios
      .get(`admin/v1/banner`, {
        params: payload,
      })
      .then((response) => {
        setBannerData(response.data.data.data);
      })
      .catch((error) => {
      });
  };

  return (
    <div>
      <section className="flat-cart-item">
        <div className="mainslider">
          <div className="themesflat-container">
            <Swiper
                autoplay={{
                  delay: 2500,
                  pauseOnMouseEnter: true,
                }}
              speed={2500}
              key={data.length}
              // pauseOnMouseEnter={true}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 1,
                },
                991: {
                  slidesPerView: 1,
                },
              }}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {bannerData.rows && bannerData.rows.length > 0 && bannerData.rows.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="row height100">
                    <div className="col-12 col-md-6 ">
                      <div className="content">
                        <div className="txtBox">
                          <div className="fs-16">
                            <h2>{item.name}<span>{item.title}</span></h2>
                            <p><span>{item.description}</span></p>
                            {item.link && (
                              <div className="centerAlign">
                                <a href={item.link} className='mintButton roundBtn roundBtnNew'>Mint</a>                                
                              </div>
                            )}
                          </div>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="imgbox">
                        <div className="videoWrap">
                          {item.type===1  ? (<img src={`${s3Url}category_image/${item.url}`}/>):
                          <video autoPlay loop playsInline muted  src={`${s3Url}category_image/${item.url}`} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

const SliderItem = (props) => (
  <div className="row">
    <div className="col-12 col-md-6 ">
      <div className="content">
        <div className="txtBox">
          <div className="fs-16">
            <h2>Asset backed NFTs<br />Explore the MetaPhy<span>Only via Paiverse</span></h2>
            <p>MetaPhy, where the meta and physical collide to create infinite possibilities</p>
            <div className="centerAlign">
              <button className='mintButton roundBtn '>
                Mint
              </button>
            </div>
          </div>
          <p></p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6">
      <div className="imgbox">
        <div className="videoWrap">
          <video autoPlay loop playsInline muted src={videoMp4} />
        </div>
      </div>
    </div>
  </div>
);

export default SliderStyle2;
