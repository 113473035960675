import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = () => {
  const isLogin = localStorage.getItem("accessToken");
  return isLogin ? <Navigate to="/" /> : <Navigate to="/login" />;
};


export default PublicRoute;
