import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import avt from "../../src/assets/images/box-item/bottle1.jpg";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";

import apiUrl from "../store/globalapiurl";
import axios from "../axiosinstance";
import { s3Url } from "../store/baseUrl";

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}


const LatestNews = () => {
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
	const [pagination, setPagination] = useState([]);

	const query = useQuery();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getLatestNews();
    }, [query]);

    const getLatestNews = () => {
        setLoading(true);
        const payload = {
            limit: 10,
            skip: query.get("skip") || 0
        };
        axios
            .get(apiUrl._getLatestNews, { params: payload }).then((res) => {
                setData(res?.data);
				let pagination = [];
				for(let i=0; i < res?.data?.data?.count % 10; i++){
					pagination.push(i);
				}
				setPagination(pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="darkGray latestNews latest-news">
                <section className=' flat-title-page inner'>
                    <h1 className="heading text-white"> Latest News </h1>
                    <p>Please follow us on social media and keep up to date on the latest news and happenings at Paiverse here.</p>
                </section>

                <div className="themesflat-container flat-title-page">
					<div className="articles"><br /> <br />
                        <h2 className="tf-title pb-24 text-left">Latest News</h2>
                        <div className="container">
                            <div className='row'>
                                {data?.data?.listing?.map((data, index)=>(
                                    <div key={index} className="col-md-4 col-md-4 col-12 mb-5">
                                        <div className="productCard latest-news">
                                            <a target='_blank' href={data.url}>
                                                <div className="d-flex justify-content-center mb-4">
                                                    <img src={`${s3Url}icons/${data.image}`} alt={data.title} />
                                                </div>
                                                <p>{data.title}</p>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
						<div className="pagination">
							{pagination.map(pagination => (
								<Link key={pagination} className="panigation-button" to={`?skip=${pagination * 10}`}>
									{pagination + 1}
								</Link>)
							)}
						</div>
                    </div>
                    {/* <div className="linkWrap">
                        <h3>What is Paiverse?</h3>
                        <p>
                            <a href="https://medium.com/@paiverse101/what-is-paiverse-c17c36929b6f" target="_blank" className="blueText">https://medium.com/@paiverse101/what-is-paiverse-c17c36929b6f</a>
                        </p>
                    
                    <h3>What is the Paiverse Universe? </h3>
                    <p> <a href="https://medium.com/@paiverse101/what-is-the-paiverse-universe-4a4aec94099c" target="_blank" className="blueText">https://medium.com/@paiverse101/what-is-the-paiverse-universe-4a4aec94099c</a></p>
                   

                    <h3>Twitter Space : AMA with Stephen Davies from Penderyn </h3>
                  
                    <p> <a href="https://twitter.com/paiverse_/status/1591483488834056197" target="_blank" className="blueText">https://twitter.com/paiverse_/status/1591483488834056197/status/1591483488834056197</a></p>

                    <h3>AGBI - US Invetsment/Brain Drain </h3>
                  
                    <p> <a href="https://www.agbi.com/articles/uae-to-benefit-from-us-cryptocurrency-brain-drain/" target="_blank" className="blueText">https://www.agbi.com/articles/uae-to-benefit-from-us-cryptocurrency-brain-drain/</a></p>

                    <h3>Nasdaq - Interview with Jane King</h3>
                  
                    <p> <a href="https://www.youtube.com/watch?v=DYDp6y0pLys" target="_blank" className="blueText">https://www.youtube.com/watch?v=DYDp6y0pLys</a></p>
                        
                    <h3>Khaleej Times - Startech Podcast</h3>
                                          
                    <p> <a href="https://podcasts.apple.com/za/podcast/startech-podcast-tim-bhatnagar-nfts-are-copyrights/id1678990681?i=1000608962031" target="_blank" className="blueText">https://podcasts.apple.com/za/podcast/startech-podcast-tim-bhatnagar-nfts-are-copyrights/id1678990681?i=1000608962031</a></p>

                    </div> */}

                    {/* <h2 className="tf-title pb-24 text-center">Coming Soon</h2>  */}
                    {/* <h2 className="tf-title pb-24 text-left">Paiverse Media</h2> */}
                    {/* <div className='row'>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>  */}
                    {/* <div className="articles"><br /> <br />
                        <h2 className="tf-title pb-24 text-left">Articles</h2>
                        <div className='row'>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>
                <div className="tf-section pt-24"><PaiverseCommunity /></div>

            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title> Latest News Modal </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <img src={avt} alt='Team Name' width="100%" height="100%" />
                </Modal.Body>
                {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default LatestNews