import React from "react";
import { Modal } from "react-bootstrap";
const ListToMarketConfirmMsg = (props) => {
    const agree = (e) => {
        // e.preventDefault();
        props.confirm("agree");
    };

    return (
        <Modal show={props.show} onHide={props.closeModal}>
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40 pt-0">
                <h3>Please note</h3>
                <p>Your NFT redemption request is under progress. If you list the NFT on the marketplace, the redemption request will be cancelled.</p>
                <button className="roundBtn width100" onClick={() => agree()}>
                    I agree
                </button>
            </div>
        </Modal>
    );
};

export default ListToMarketConfirmMsg;