import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/img_logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import Countdown from "react-countdown";
import { s3Url } from "../store/baseUrl";
// import CardModal from "../CardModal";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const BrandLiveAuction = (props) => {
    const data = props.releaseData.rows;


    return (
        <Fragment>
            <section className="releasesBrand">
                <h2 className="tf-title text-left pb-24">Releases</h2>

                <div className="row">
                    <div className="col-md-12">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={30}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                767: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                                1300: {
                                    slidesPerView: 4,
                                },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            {data.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="swiper-container show-shadow carousel auctions">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="sc-card-product">
                                                    <Link to={`/releaseDetails/${item.id}`}>
                                                        <div className="card-media">
                                                            <img src={`${s3Url}icons/${item.imageUrl}`} alt="img" />
                                                        </div>
                                                        <div className="card-title d-flex">
                                                            <div className="left">
                                                                <h3>
                                                                {item.title}
                                                                </h3>
                                                            </div>
                                                            <div className="button-place-bid">
                                                                <span className="tags more">
                                                                    Explore More
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>

        </Fragment>
    );
};

export default BrandLiveAuction;
