import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
// import { getContract } from "../Helpers/contract";
import img1 from "../assets/images/icon/connect-1.png";
import img5 from "../assets/images/icon/connect-5.png";
import img4 from "../assets/images/icon/TWT.png";
//import { ethers } from "ethers";
//import Web3 from 'web3';
import axios from "./../axiosinstance";
import { Buffer } from "buffer";
import Context from "../store/context";
global.Buffer = Buffer;

const WalletConnect = () => {
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [walletlinkProvider, setWalletlinkProvider] = React.useState();
  const [walletDetails, setWalletDetails] = React.useState();

  React.useEffect(() => {
    getConnectWallet();
  }, []);

  const getConnectWallet = () => {
    axios
      .get(`api/v1/user/connectUserWallet`)
      .then((response) => {     
        if (response.data.statusCode == 200) {
        
          //const web3 = new Web3(Web3.givenProvider);
         
          /*web3.eth.getBalance(response.data.data.walletId).then((res) => {
            if(res){
              let walletAmount = (res/ 1000000000000000000).toFixed(4);
              response.data.data.walletAmount = walletAmount;
            }
          });
          console.log('response.data.data',response.data.data);*/
          setWalletDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.log('error.response::::',error);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  };
  // connect metamask
  const connectWalletHandler = async () => { 
    try {
      const res = await connectMetamask();  
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      saveWalletDetails("MetaMask", res.account);
    } catch (e) {

    }
  };

  // connect coinbase wallet
  const connectConinbaseWalletHandler = async () => {
    try {
      const res = await connectCoinbase();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      saveWalletDetails("Coinbase", res.account);
    } catch (e) {
    }
  };

  // check for linked wallet address with current email
  const saveWalletDetails = (walletType, account) => {
    // setLoading(true);
    axios
      .put(
        `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
        {}
      )
      .then((response) => {

        if (response.data.statusCode == 200) {
          const toasterError = {
            type: "success",
            message: "Wallet connected successfully",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getConnectWallet();
          // setLoading(false);
          localStorage.setItem("activeWallet", walletType);
        }
      })
      .catch((error) => {

        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        // setLoading(false);
      });
  };
  const [data] = useState([
    {
      img: img1,
      title: "MetaMask",
      function:connectWalletHandler,
      description:
        "Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets",
    },
    {
      img: img5,
      function:connectConinbaseWalletHandler,
      title: "Coinbase Wallet",
      description:
        "Coinbase Wallet is a self-custody wallet, giving you complete control of your crypto.Additional security options on all of your devices provide more ways to keep your crypto safe and secure",
    },   
    // {
    //   img: img4,
    //   function:connectTrustWalletHandler,
    //   title: "Trust Wallet",
    //   description:
    //     "Trust Wallet is a fast and secure multi crypto wallet with Binance DEX support, designed for ease of use and perfect for storing your different crypto assets.",
    // },

    
  ]);
  return (
    <div>
      {/* <LoadingOverlay active={loading} spinner={true} text="Loading..."> */}
      <section className="flat-title-page inner">
        <div className="page-title-heading mg-bt-12">
          <h1 className="heading text-center">Connect Wallet</h1>
        </div>
      </section>
      <div className="tf-connect-wallet darkGray tf-section">
        {/* <button className="TheButton" onClick={mintNFT}>
          Mint
        </button> */}
        <div className="themesflat-container">
          <div className="row">
            {walletDetails?.walletType && (
              <div className="col-12">
                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                  {walletDetails &&
                    `Currently your ${walletDetails.walletType}
                wallet is connected`}
                </h2>
                <h5 className="sub-title ct style-1 pad-400">
                  Wallet Address : {walletDetails && walletDetails.walletId}
                </h5>
              </div>
            )}
            <div className="col-md-12">
              <div className="sc-box-icon-inner style-2 d-flex justify-content-center">
                {data.map((item, index) => (
                  <div key={index} className="sc-box-icon">
                    <div className="img">
                      <img
                        className="pointer"
                        src={item.img}
                        onClick={item.function}
                        alt="Axies"
                      />
                    </div>
                    <h4 className="heading">
                      <Link
                        to=""
                        onClick={item.function}
                      >
                        {item.title}
                      </Link>
                      {/* <Link to="/login" >{item.title}</Link>{" "} */}
                    </h4>
                    <Link
                      to=""
                      onClick={item.function}
                    >
                      <p className="content">{item.description}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {/* <Link
              to=""
              onClick={connectTrustWalletHandler}
            >
             truest adjsddj
            </Link> */}
          </div>
        </div>
      </div>
      {/* </LoadingOverlay> */}
    </div>
  );
};

export default WalletConnect;
