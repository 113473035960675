import React, { useState } from "react";
import { Modal } from "react-bootstrap";
const BidingConfirmMsg = (props) => {
    const [bidPrice, setBidPrice] = useState();


    React.useEffect(() => {
        setBidPrice(props.sendToPrice)
    }, [props]);


    const agree = (e) => {
        // e.preventDefault();
        props.confirm("agree");
    };

    return (
        <Modal show={props.show} onHide={props.closeModal}>
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40 pt-0">
                <h3>Accept Bid</h3>
                <div className="pb-10 pt-10">
                    <p> I confirm my intention to sell my NFT for <span style={{display: "inline-block"}}>{bidPrice} ETH</span> </p>
                </div>
                <button className="roundBtn width100" onClick={() => agree()}>
                    Confirm
                </button>
            </div>
        </Modal>
    );
};

export default BidingConfirmMsg;