import React, { useState, useContext } from "react";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import { PASSWORD_REGEX } from "../services/contants";

const EditProfile = () => {
  LoadingOverlay.propTypes = undefined
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    username: "",
    password: "",
  };

  const password = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [passwordValues, setPasswordValues] = useState(password);
  let [passwordShow, setPasswordShow] = useState(false);
  let [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  let [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(true);
  React.useEffect(() => {
    getProfile();
  }, []);

  React.useEffect(() => {
   
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      onSubmit();
    }
  }, [formErrors]);

  const getProfile = () => {
    axios
      .get(`api/v1/user/getProfile`)
      .then((response) => {
 
        if (response.data.data) {
          const initialValues = {
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            email: response.data.data.email,
            country: response.data.data.countryName
              ? response.data.data.countryName
              : "--",
            username: response.data.data.userName,
          };
          setFormValues(initialValues);
        }
        setLoading(false);
      })
      .catch((error) => {
       
      });
  };

  const togglePassword = (item) => {
    item = !item;
    setPasswordShow(item);
  };

  const toggleConfirmPassword = (item) => {
    item = !item;
    setConfirmPasswordShow(item);
  };

  const toggleCurrentPassword = (item) => {
    item = !item;
    setCurrentPasswordShow(item);
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;

    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(passwordValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.newPassword) {
      errors.password = "Password is required.";
    } else if (!PASSWORD_REGEX.test(values.newPassword)) {
      errors.password = "Password should be in required format.";
    } else if (values.newPassword.length < 8) {
      errors.password = "Password must be more than 8 characters.";
    } else if (values.newPassword.length > 20) {
      errors.password = "Password cannot exceed  more than 20 characters.";
    }

    if (!values.currentPassword) {
      errors.currentPassword = "Password is required.";
    } else if (!PASSWORD_REGEX.test(values.currentPassword)) {
      errors.currentPassword = "Password should be in required format.";
    } else if (values.currentPassword.length < 8) {
      errors.currentPassword = "Password must be more than 8 characters.";
    } else if (values.currentPassword.length > 20) {
      errors.currentPassword =
        "Password cannot exceed  more than 20 characters.";
    } else if (values.currentPassword === values.newPassword) {
      errors.currentPassword =
        "Password cannot exceed  more than 20 characters.";
    }

    if (!values.confirmNewPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmPassword =
        "Confirm Password should be same as password entered in Set Password field.";
    }

    return errors;
  };

  const PasswordErrorDown = (values) => {
    let error = "";
    if (isSubmit || values.newPassword) {
      if (!values.newPassword) {
        error = "Password is required.";
      } else if (values.currentPassword === values.newPassword) {
        error = "New Password should not be same as old Password.";
      }
    }
    return <p className="error">{error}</p>;
  };

  const currentPasswordErrorDown = (values) => {
    let error = "";
    if (isSubmit || values.currentPassword) {
      if (!values.currentPassword) {
        error = "Current Password is required.";
      }
    }
    return <p className="error">{error}</p>;
  };

  const confirmPasswordError = (values) => {
    if (isSubmit || values.confirmNewPassword) {
      let error = "";
      if (!values.confirmNewPassword) {
        error = "Confirm Password is required.";
      } else if (values.newPassword !== values.confirmNewPassword) {
        error =
          "Confirm Password should be same as password entered in Set Password field.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const PasswordError = (values) => {
    let error = "";
    if (isSubmit || values.newPassword) {
      if (!values.newPassword) {
        error = "Password is required.";
      } else if (!PASSWORD_REGEX.test(values.newPassword)) {
        error =
          "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
      } else if (values.newPassword.length < 8) {
        error = "Password must be more than 8 characters.";
      } else if (values.newPassword.length > 20) {
        error = "Password cannot exceed  more than 20 characters.";
      }
    }
    if (!PASSWORD_REGEX.test(values.newPassword)) {
      error =
        "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
    }
    return (
      error && (
        <p className="error" style={{ color: "green" }}>
          {error}
        </p>
      )
    );
  };

  const currentPasswordError = (values) => {
    let error = "";
    if (isSubmit || values.currentPassword) {
      if (!values.currentPassword) {
        error = "Password is required.";
      } else if (!PASSWORD_REGEX.test(values.currentPassword)) {
        error =
          "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
      } else if (values.currentPassword.length < 8) {
        error = "Password must be more than 8 characters.";
      } else if (values.currentPassword.length > 20) {
        error = "Password cannot exceed  more than 20 characters.";
      }
    }
    if (!PASSWORD_REGEX.test(values.currentPassword)) {
      error =
        "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
    }
    return (
      error && (
        <p className="error" style={{ color: "green" }}>
          {error}
        </p>
      )
    );
  };

  const onSubmit = () => {

    setLoading(true);
    const payload = {
      oldPassword: passwordValues.currentPassword,
      password: passwordValues.confirmNewPassword,
    };

    axios
      .put(`api/v1/user/change_password`, payload)
      .then((response) => {
 
        if (response.data.statusCode == 200) {
          setLoading(false);
          const toasterError = {
            type: "success",
            message: "password changed successfully",
          };
          const password = {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          };
          setPasswordValues(password);
          setIsSubmit(false);
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {
  
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        setLoading(false);
      });
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <section className="flat-title-page inner">
          <div className="page-title-heading mg-bt-12">
            <h1 className="heading text-center">Profile</h1>
          </div>
        </section>
        <div className="darkGray tf-section">
          <div className="themesflat-container">
            <div className="form-upload-profile">
              <div className="form-infor-profile">
                <div className="info-account w-100">
                  <h4 className="title-create-item">Account info</h4>
                  <div className="row">
                    <div className="col-xl-6 col-md-12">
                      <fieldset>
                        <h4 className="title-infor-account">First Name</h4>
                        <input
                          type="text"
                          placeholder="Trista Francis"
                          value={formValues.firstName}
                          disabled
                        />
                      </fieldset>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <fieldset>
                        <h4 className="title-infor-account">Last Name</h4>
                        <input
                          type="text"
                          value={formValues.lastName}
                          placeholder="Axies.Trista Francis.com/"
                          disabled
                        />
                      </fieldset>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <fieldset>
                        <h4 className="title-infor-account">
                          Email Address
                        </h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          value={formValues.email}
                          disabled
                        />
                      </fieldset>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <fieldset>
                        <h4 className="title-infor-account">Username</h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          disabled
                          value={formValues.username}
                        />
                      </fieldset>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <fieldset>
                        <h4 className="title-infor-account">Country</h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          disabled
                          value={formValues.country}
                        />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-upload-profile">
              <form className="form-profile" onSubmit={handleSubmit}>
                <div className="form-infor-profile">
                  <div className="info-account w-100">
                    <h4 className="title-create-item">Change Password</h4>
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        {passwordValues.currentPassword &&
                          currentPasswordError(passwordValues)}
                        <div className="form-group position-relative">
                          <input
                            id="pass"
                            name="currentPassword"
                            value={passwordValues.currentPassword}
                            onChange={handleChangePassword}
                            type={currentPasswordShow ? "text" : "password"}
                            placeholder="Current Password *"
                          />
                          {passwordValues.currentPassword && (
                            <i
                              className={
                                currentPasswordShow
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                              onClick={() =>
                                toggleCurrentPassword(currentPasswordShow)
                              }
                            ></i>
                          )}
                          {!formValues.currentPassword &&
                            currentPasswordErrorDown(passwordValues)}
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-12">
                        {passwordValues.newPassword &&
                          PasswordError(passwordValues)}
                        <div className="form-group position-relative">
                          <input
                            id="pass"
                            name="newPassword"
                            value={passwordValues.newPassword}
                            onChange={handleChangePassword}
                            type={passwordShow ? "text" : "password"}
                            placeholder="New Password *"
                          />
                          {passwordValues.newPassword && (
                            <i
                              className={
                                passwordShow
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                              onClick={() => togglePassword(passwordShow)}
                            ></i>
                          )}
                          {!formValues.newPassword &&
                            PasswordErrorDown(passwordValues)}
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-12">
                        <div className="form-group position-relative">
                          <input
                            id="pass"
                            name="confirmNewPassword"
                            value={passwordValues.confirmNewPassword}
                            onChange={handleChangePassword}
                            type={confirmPasswordShow ? "text" : "password"}
                            placeholder="Confirm New Password *"
                          />
                          {passwordValues.confirmNewPassword && (
                            <i
                              className={
                                confirmPasswordShow
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                              onClick={() =>
                                toggleConfirmPassword(confirmPasswordShow)
                              }
                            ></i>
                          )}
                          {confirmPasswordError(passwordValues)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="exploreMore">
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default EditProfile;
