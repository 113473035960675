import React, { useEffect, useState } from "react";
import axios from "../axiosinstance";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import LoadingOverlay from "react-loading-overlay";
import apiUrl from "../store/globalapiurl";
import * as moment from 'moment'

const Terms = () => {
    LoadingOverlay.propTypes = undefined
    let [alldata, setData] = React.useState([]);
    let [loading, setLoading] = useState(true);
    React.useEffect(() => {
        getList();
    }, [])
    const getList = () => {
        const payload = {
            type: 1
        };
        axios
            .get(apiUrl._getPage, { params: payload }).then((res) => {
                let data = res.data.data;
                setData(data);
                setLoading(false);
            })
            .catch((error) => {

            });
    };

    return (
        <>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
                <section className='flat-title-page inner'>
                    <h1 className="heading text-white">{alldata.title ? alldata.title : 'Terms of Service'} </h1>
                    <p>Last Updated: {alldata.updatedAt ? moment(alldata.updatedAt).format('MMMM YYYY') : ''}</p>
                </section>
                <section className='paiverseTeam  text-left darkGray '>
                    <div className="themesflat-container  tf-section">
                        <div className='termsWrap'>
                            <div dangerouslySetInnerHTML={{ __html: alldata.description }} />

                            {/* <p className="bold">LAST UPDATED</p>
                            <p>These Terms were last updated: {alldata.updatedAt ? moment(alldata.updatedAt).format('YYYY-MM-DD') : ''}
                            </p> */}
                        </div>
                        <div className='mt-40'>
                            <PaiverseCommunity />
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
        </>
    )
}

export default Terms