import React from "react";
import { Modal } from "react-bootstrap";
const SendEmailModel = (props) => {
    const close = (e) => {
        // e.preventDefault();
        props.confirm("close");
    };

    return (
        <Modal show={props.show} onHide={props.closeModal}>           
            <div className="modal-body space-y-20 pd-40 ">
                <h3>Email Sent</h3>
                <p>You have been sent and email explaining the steps.</p>
                <button className="roundBtn alignCenter"  onClick={()=>close()}>
                  OK
                </button>
            </div>
        </Modal>
    );
};

export default SendEmailModel;