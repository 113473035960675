import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import { getContract, ipfsUrl } from "../Helpers/contract";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import * as moment from "moment";
import SuccessModal from "../components/layouts/SuccessModal";
import {s3Url} from "../store/baseUrl";
const ItemDetails01 = () => {
   LoadingOverlay.propTypes = undefined
  const location = useLocation();
  let navigate = useNavigate();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
  const [bottleId, setBottleId] = useState();
  const [showModal, setShowModal] = React.useState(false);
  let [closeModal, setCloseModal] = React.useState(false);
  const [walletType, setWalletType] = useState();

  React.useEffect(() => {
    const [a, id] = location.search.split("?id=");
    setBottleId(id);
    getReleaseBottelsDetails(id);
    getWallet();
  }, []);

  const getWallet = () => {
    axios
      .get(`api/v1/user/connectUserWallet`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setWalletType(response.data.data.walletType);
          // setWalletDetails(response.data.data);
        }
      })
      .catch((error) => {

      });
  };

  const getReleaseBottelsDetails = (id) => {
    axios
      .get(`api/v1/liveStore/${id}`)
      .then((response) => {
        setReleaseBottleDetails(response.data.data);
        setLoading(false);

      })
      .catch((error) => {
      });
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {

          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const connectWalletHandler = async (id) => {
    try {
      const res =walletType==="Coinbase" ?  await connectCoinbase() : await connectMetamask() ;
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      // const activeWallet = localStorage.getItem("activeWallet");
      // if (!activeWallet) {
      const resolve = await saveWalletDetails(walletType, res.account);
      // }
      if (resolve) {
        axios
          .get(`api/v1/liveStore/priceAndToken/${id}?buyType=BUY`)
          .then((response) => {
            if (response.data.statusCode == 200) {
              mintBottleNFT(
                res.library,
                res.account,
                id,
                response.data.data.token,
                response.data.data.price.toString()
              );
            }
          })
          .catch((error) => {
            setLoading(false);
          });
        // mintBottleNFT(
        //   res.library,
        //   res.account,
        //   id,
        //   0.01
        // )
      }
    } catch (e) {
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };

  // mint nft with main contract
  const mintBottleNFT = async (library, account, id, token, price) => {
    setLoading(true);
    try {
      const priceUSD = localStorage.getItem("priceInUSD") || 0;
      const PriceInETH = price * priceUSD;
      const myContract = getContract(library, account);
      const overrides = {
        // gasLimit: 230000,
        value: ethers.utils.parseEther(PriceInETH.toFixed(6).toString()),
      };

      let voucher = {
        uri: ipfsUrl+ releaseBottleDetail.fileHash,
        minPrice: releaseBottleDetail.price,
        signature: releaseBottleDetail.signature,
      };
      const response = await myContract.safeMint(account, voucher);
      const receipt = await response.wait();
     
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
          10 ** 18
      );
      payment(receipt, 0.01, receiptTokenId, id, token);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const mintNFT = (id) => {
    if (globalState.isLoggedIn) {
      connectWalletHandler(id);
    } else {
      navigate(`/login`, { state: "/release" });
    }
  };

  const closePopup = () => {
    setShowModal(false);
  };

  const getData = (route) => {
    navigate(route);
  };

  const formatTime = (time) => {
    return moment(time).format("MMM DD, yyyy");
  };

  // save blockchain transaction
  const payment = (paymentData, dollarPrice, token, id, oldNFTToken) => {
    const payload = {
      token: oldNFTToken,
      receiptTokenId: token,
      id: id,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: paymentData.from,
      currency: "ETH",
      to: paymentData.to,
      gasAmount: 2300,
      conversionAmount: dollarPrice,
    };
    axios
      .post(`api/v1/liveStore/payment`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setShowModal(true);
          setLoading(false);
          getReleaseBottelsDetails(id);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // convert ETH to USD
  const ETHtoUSDPrice = (value) => {
    // const ethPrice = localStorage.getItem("priceInETH") || 0;
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  return (
    <div className="item-details">
      <SuccessModal
        show={showModal}
        onHide={closeModal}
        redirect={getData}
        closeModal={closePopup}
      />
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <section className="flat-title-page inner">
          <div className="overlay"></div>

          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">
                    {releaseBottleDetail &&
                      capitalizeFirstLetter(releaseBottleDetail.title)}
                  </h1>
                </div>
               
              </div>
            </div>
          </div>
        </section>
        <div className="tf-section tf-item-details">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="content-left">
                  <div className="media">
                    {/* <video width="750" height="500" autoPlay muted>
                      <source src={video} type="video/mp4" />
                    </video> */}
                    <img
                      src={`${s3Url}icons/${
                        releaseBottleDetail && releaseBottleDetail.imageUrl
                      }`}
                      alt="Axies"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="content-right">
                  <div className="sc-item-details">
                    <h2 className="style2">
                      {releaseBottleDetail && releaseBottleDetail.title}
                    </h2>
                    
                    
                    <p>
                      {releaseBottleDetail && releaseBottleDetail.description}
                    </p>
                    <div className="meta-item-details style2">
                      <div className="item meta-price w-100">
                        <span className="heading">Price</span>
                        <div className="price">
                          <div className="price-box d-flex align-items-center">
                            <h5>
                              {" "}
                              {releaseBottleDetail &&
                                releaseBottleDetail.price}{" "}
                              USD
                            </h5>
                            {/* <span>= $12.246</span> */}
                            <span>
                              (
                              {ETHtoUSDPrice(
                                releaseBottleDetail && releaseBottleDetail.price
                              )}
                              &nbsp;ETH)
                            </span>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    {/* <Link
                    to="/wallet-connect"
                    className="sc-button loadmore style bag fl-button pri-3"
                  >
                    <span>Mint</span>
                  </Link> */}
                    <button
                      onClick={() => mintNFT(bottleId)}
                      disabled={!loading && !releaseBottleDetail.inStock}
                      className={`sc-button loadmore style bag fl-button pri-3 w-100 ${
                        !loading && !releaseBottleDetail.inStock && `color-red`
                      }`}
                    >
                      <span>
                        {releaseBottleDetail && releaseBottleDetail.inStock
                          ? "Mint"
                          : !releaseBottleDetail.inStock && "Sold Out"}
                      </span>
                    </button>
                    <div className="flat-tabs themesflat-tabs">
                      <Tabs>
                        <TabList>
                          <Tab>Token History</Tab>
                        </TabList>

                        <TabPanel>
                          <ul className="bid-history-list">
                            {releaseBottleDetail &&
                              releaseBottleDetail.productTransactions?.length >
                                0 &&
                              releaseBottleDetail.productTransactions.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="content">
                                      <div className="client">
                                        <div className="sc-author-box style-2">
                                          <div className="author-avatar">
                                            <Link to="#">
                                              <img
                                                src={img1}
                                                alt="Axies"
                                                className="avatar"
                                              />
                                            </Link>
                                            <div className="badge"></div>
                                          </div>
                                          <div className="author-infor">
                                            <div className="name">
                                              <span> Minted By&nbsp;</span>
                                              <h6 className="text-capitalize">
                                                {/* <Link to="/author-02"> */}
                                                {item.buyers.userName}
                                                {/* </Link> */}
                                              </h6>{" "}
                                            </div>
                                            <div className="name">
                                              <span className="time">
                                                {formatTime(item.createdAt)}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="price">
                                        <h5>
                                          USD&nbsp;{item.totalAmount}&nbsp;
                                          <span>
                                            ({ETHtoUSDPrice(item.totalAmount)}
                                            ETH)
                                          </span>
                                        </h5>
                                        {/* <span>= {item.totalAmount}</span> */}
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            {releaseBottleDetail &&
                              !releaseBottleDetail.productTransactions
                                ?.length && (
                                <li>
                                  <h4>No token history</h4>
                                </li>
                              )}
                          </ul>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ItemDetails01;
