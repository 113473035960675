import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import axios from "../../../axiosinstance";
import PaiverseCommunity from './PaiverseCommunity';
import { Link } from "react-router-dom";

const HomeFaq = () => {   
    const [faqList, setfaqList] = useState([]);  
    const [data] = useState([]);
    React.useEffect(() => {
        getFAQ();
    });
    const getFAQ = () => {
        axios
            .get(`admin/v1/faq/FAQsByCategoryidGroup`)
            .then((response) => {
                if (response.data.data.count > 0) {
                    Object.keys(response.data.data.listing).map((item, i) => {
                        data.push({ key: item, value: response.data.data.listing[item] });
                    });
                    setfaqList(data);
                }              
            })
            .catch((error) => {

            });
    };
   
    return (
        <>
        {/* pt-10 */}
            <section className="tf-section  wrap-accordion darkGray">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-40">
                        Frequently Asked Questions
                    </h2>
                    <div className="flat-accordion2">
                       
                        {faqList.length &&
                            faqList.map((item, index) => (
                                <div className="flat-accordion2" key={index}>
                                    {index < 1 ? <> {item.value &&
                                        item.value.map((faq, i) => (
                                            <div key={i}>
                                                {i < 5 ? 
                                                    <Accordion key={i} title={faq.title}>
                                                        {item.key !== "Roadmap" && (
                                                            // <p>{faq.description}</p>
                                                            <p dangerouslySetInnerHTML={{ __html: faq.description }} />
                                                        )}

                                                    </Accordion>
                                                : ""}
                                             </div>

                                        ))}</> : ""}

                                    {/* {index < faqList.length - 1 && (
                                        <div className="saparator"></div>
                                    )} */}
                                </div>
                            ))}
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/faq" className="exploreMore">
                            Explore More
                        </Link>
                    </div>
                </div>
                <div className='mt-40'>
                    <PaiverseCommunity />
                </div>
            </section>
        </>
    )
}

export default HomeFaq