import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../axiosinstance";
import { ethers } from "ethers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connectMetamask, connectCoinbase, } from "../../../Helpers/contractMethods";
import { getContract } from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import BidingConfirmMsg from "../home-8/BiddingMsg";
import * as moment from 'moment';
import { s3Url } from "../../../store/baseUrl";

const MyOfferListing = () => {
  LoadingOverlay.propTypes = undefined;
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [bidPrice, setBidPrice] = useState();
  const [acceptBidData, setAcceptBidData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  const [myOfferTab] = useState([
    {
      id: 1,
      title: "Offers on my listings",
    },
    {
      id: 2,
      title: "Offers I made",
    },
  ]);
  let [myoffers, setMyoffers] = useState([]);
  let [myBid, setMyBid] = useState([]);
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    // setLoading(true);
    getMyOffers();
    offerImade();

  }, []);

  const getMyOffers = () => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      // skip: skip,
      // limit: 10, 
    };
    axios
      .get(`api/v1/bid/recivedBids`)
      .then((response) => {
        setLoading(false);
        let data = response.data.data
        setMyoffers(data);
        setShowNoData(true)
        
      })
      .catch((error) => {
      });
  };
  const estDate1 = (date) => {
    let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
    let newDate = moment(date1).format('YYYY-MM-DD');
    return newDate;
  }
  const estDate = (date) => {
    let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
    let newDate = moment(date1).format('YYYY-MM-DD');
    return newDate;
  }
  const lowToHignt = (id, type) => {
    if (type === "listing") {
      if (myoffers.length > 1) {
        setIsActive(id)
        let data = myoffers;    
        setShowNoData(false)
        // myoffers = [];
        // setMyoffers(myoffers);
        setTimeout(() => {
          myoffers = data.sort((a, b) => (a.amount > b.amount ? 1 : -1));
          setMyoffers(myoffers);
          setShowNoData(true)
         
        }, 10);
      }
    } else {
      if (myBid.length > 1) {
        setIsActive(id)
        setShowNoData(false)
        let data = myBid
        // myBid = [];
        // setMyBid(myBid);
        setTimeout(() => {
          setShowNoData(true)
          myBid = data.sort((a, b) => (a.amount > b.amount ? 1 : -1));
          setMyBid(myBid);

        }, 10);
      }

    }

  }
  const higntToLow = (id, type) => {
    if (type === "listing") {
      if (myoffers.length > 1) {
        setIsActive(id)
        let data = myoffers;    
        setShowNoData(false)
        // myoffers = [];
        // setMyoffers(myoffers);
        setTimeout(() => {
          myoffers = data.sort((a, b) => (b.amount > a.amount ? 1 : -1));
          setMyoffers(myoffers);
          setShowNoData(true)
         
        }, 10);
      }
    } else {
      if (myBid.length > 1) {
        setIsActive(id)
        let data = myBid
        setShowNoData(false)
        // myBid = [];
        // setMyBid(myBid);
        setTimeout(() => {
          setShowNoData(true)
          myBid = data.sort((a, b) => (b.amount > a.amount ? 1 : -1));
          setMyBid(myBid);

        }, 10);
      }
    }
  }
  const lowToHigntDate = (id, type) => {
    if (type === "listing") {
      if (myoffers.length > 1) {
        setIsActive(id)
        let data = myoffers
        setShowNoData(false)
        // myoffers = [];
        // setMyoffers(myoffers);
        setTimeout(() => {
          setShowNoData(true)
          myoffers = data.sort((a, b) => (estDate(a.expiredAt) > estDate(b.expiredAt) ? 1 : -1));
          setMyoffers(myoffers);
        }, 10);
      }
    }
    else {
      if (myBid.length > 1) {
        setIsActive(id)
        let data = myBid;
        setShowNoData(false)
        // myBid = [];
        // setMyBid(myBid);
        setTimeout(() => {
          setShowNoData(true)
          myBid = data.sort((a, b) => (estDate(a.expiredAt) > estDate(b.expiredAt) ? 1 : -1));
          setMyBid(myBid);

        }, 10);
      }
    }
  }
  const higntToLowDate = (id, type) => {
    if (type === "listing") {
      if (myoffers.length > 1) {
        setIsActive(id)
        let data = myoffers
        setShowNoData(false)
        // myoffers = [];
        // setMyoffers(myoffers);
        setTimeout(() => {
          setShowNoData(true)
          myoffers = data.sort((a, b) => (estDate(b.expiredAt) > estDate(a.expiredAt) ? 1 : -1));
          setMyoffers(myoffers);
        }, 10);
      }
    } else {
      if (myBid.length > 1) {
        setIsActive(id)
        let data = myBid
        setShowNoData(false)
        // myBid = [];
        // setMyBid(myBid);
        setTimeout(() => {
          setShowNoData(true)
          myBid = data.sort((a, b) => (estDate(b.expiredAt) > estDate(a.expiredAt) ? 1 : -1));
          setMyBid(myBid);

        }, 10);
      }
    }
  }

  const loadMore = () => {
    skip = skip + 9;
    setSkip(skip);
    getMyOffers();
  };


  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }


  const offerImade = () => {
    const payload = {
      // search: globalState.searchQuery ? globalState.searchQuery : "",
      // skip: skip,
      // limit: 10, 
    };
    axios
      .get(`api/v1/bid/myBids`)
      .then((response) => {
        let data = response.data.data
        setMyBid(data);
        setShowNoData(true)
        setLoading(false);
      })
      .catch((error) => {
      });
  };
  const checkOwnerOf = async (token) => {
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);
    const response = await myContract.ownerOf(token);
    return response;
  }
  const closePopup = () => {
    setConfirmPopup(false);
    setAcceptBidData("")
  };

  const acceptBid = async (item) => {
    setConfirmPopup(true);
    setBidPrice(item.amount)
    setAcceptBidData(item)
  }
  const getConfirmData = async (data) => {
    setConfirmPopup(false);
    acceptConfirm(acceptBidData);
  }
  const acceptConfirm = async (item) => {
    setLoading(true);
    setAcceptBidData("")
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);
    const ownerID = await checkOwnerOf(item?.productItem?.blockChainNFTId);
    if (ownerID.toString().toLowerCase() === res.account.toString().toLowerCase()) {
      checkBid(item);
    } else {
      const toasterError = {
        type: "error",
        message: "Please use the wallet you have connected to your Paiverse account",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
      setLoading(false);
    }

  }

  const checkBid = async (item) => {
    setLoading(true);
    const payload = {
      bidId: item.id
    };
    axios
      .put(`/api/v1/bid`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          const toasterError = {
            type: "success",
            message: "Offer has been accepted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getMyOffers();
          offerImade();
          // bidTransfer(item, response.data?.data?.transaction.id)
        }
      })
      .catch((error) => {
        setLoading(false);
      });;
  }

  const bidTransfer = async (item, transactionID) => {
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);

    try {
      const tokentTransfer = await myContract['safeTransferFrom(address,address,uint256)'](res.account.toString(), item.fromAddress.toString(), item?.productItem.blockChainNFTId)

      if (tokentTransfer) {
        bidDone(item.id, transactionID, tokentTransfer)


      }
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      setLoading(false);
    }
  }

  const bidDone = async (id, transactionID, data) => {
    setLoading(true);
    const payload = {
      bidId: id,
      transactionId: transactionID,
      tokenTransferHash: data.hash
    };
    axios
      .post(`/api/v1/bid/transferToken`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          getMyOffers();
          offerImade();
          // navigate(`/marketplace`);
          const toasterError = {
            type: "success",
            message: "Offer has been accepted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getMyOffers();
          offerImade();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const cancelBid = async (item) => {
    setLoading(true);
    axios
      .delete(`/api/v1/bid`, { data: { 'bidId': item.id } })
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          getMyOffers();
          offerImade();
          const toasterError = {
            type: "success",
            message: "Bid Cancelled.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          // getReleaseBottelsDetails(bottleId);
          // getOfferDetails(bottleId);

        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }


  return (
    <Fragment>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className="airDropWrap">

          <div className="themesflat-container">
            <Tabs className="myCollectionTab">
              <TabList>
                {myOfferTab.map((data) => (
                  <Tab key={data.id}>{data.title}</Tab>
                ))}
              </TabList>
              <TabPanel>
                <div className="mycollactionHTML collection  mg-bt-50">
                  <div className="tableWrapper offerWrap">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Bidder</th>
                          <th>Bid <span className="sortByWrap">
                            <span className={isActive === 1 ? 'activeSort top' : 'top'} onClick={() => lowToHignt(1, "listing")}><i className="fas fa-caret-up"></i></span>
                            <span className={isActive === 2 ? 'activeSort bottom' : 'bottom'} onClick={() => higntToLow(2, "listing")}><i className="fas fa-caret-down"></i></span>
                          </span></th>
                          <th>Bid Expiry  <span className="sortByWrap">
                            <span className={isActive === 3 ? 'activeSort top' : 'top'} onClick={() => lowToHigntDate(3, "listing")}><i className="fas fa-caret-up"></i></span>
                            <span className={isActive === 4 ? 'activeSort bottom' : 'bottom'} onClick={() => higntToLowDate(4, "listing")}><i className="fas fa-caret-down"></i></span>
                          </span></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {myoffers.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <Link to={`/marketPlace-item-details?id=${item?.productItemId}`}>
                                <div className="imgBox">
                                  <img
                                    src={`${s3Url}icons/${item?.productItem?.product.imageUrl}`}
                                    alt="Paiverse"
                                  />
                                </div>

                                {capitalizeFirstLetter(item?.productItem?.product.title)}
                              </Link>

                            </td>
                            <td>                             
                                {capitalizeFirstLetter(item?.productBidUser.userName)}
                              
                            </td>
                            <td>                              
                                {item.amount} ETH
                              
                            </td>
                            <td>
                             
                                {estDate1(item?.expiredAt)}
                             
                            </td>
                            <td>
                              <button className="offerBtn" onClick={() => acceptBid(item)}>Accept offer</button>

                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                    {!myoffers?.length && !loading && showNoData && (

                      <div className="noData">
                        No data
                      </div>
                    )}
                  </div>

                </div>
              </TabPanel>
              <TabPanel>
                <div className="mycollactionHTML mg-bt-50">
                  <div className="tableWrapper offerWrap">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          {/* <th>Bidder</th> */}
                          <th>Bid <span className="sortByWrap">
                            <span className={isActive === 5 ? 'activeSort top' : 'top'} onClick={() => lowToHignt(5, "made")}><i className="fas fa-caret-up"></i></span>
                            <span className={isActive === 6 ? 'activeSort bottom' : 'bottom'} onClick={() => higntToLow(6, "made")}><i className="fas fa-caret-down"></i></span>
                          </span></th>
                          <th>Bid Expiry  <span className="sortByWrap">
                            <span className={isActive === 7 ? 'activeSort top' : 'top'} onClick={() => lowToHigntDate(7, "made")}><i className="fas fa-caret-up"></i></span>
                            <span className={isActive === 8 ? 'activeSort bottom' : 'bottom'} onClick={() => higntToLowDate(8, "made")}><i className="fas fa-caret-down"></i></span>
                          </span></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {myBid.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <Link to={`/marketPlace-item-details?id=${item?.productItemId}`}>
                                <div className="imgBox">
                                  <img
                                    src={`${s3Url}icons/${item?.productItem?.product.imageUrl}`}
                                    alt="Paiverse"
                                  />
                                </div>
                                {capitalizeFirstLetter(item?.productItem?.product?.title)}
                              </Link>
                            </td>
                            {/* <td>
                              <Link to={`/marketPlace-item-details?id=${item?.productItemId}`}>
                                {capitalizeFirstLetter(item?.productBidUser.userName)}
                              </Link>
                            </td> */}
                            <td>
                             
                                {item.amount} ETH
                             
                            </td>
                            <td>
                              
                                {estDate1(item?.expiredAt)}
                             
                            </td>
                            <td>
                              <button className="cancelBtn btn-close" onClick={() => cancelBid(item)}></button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {!myBid?.length && !loading && showNoData && (
                      <div className="noData">
                        No data
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Tabs>

          </div>
        </div>

        <BidingConfirmMsg show={confirmPopup}
          confirm={getConfirmData}
          sendToPrice={bidPrice}
          closeModal={closePopup} />
      </LoadingOverlay>
    </Fragment>
  );
};

export default MyOfferListing;
