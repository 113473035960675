import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../axiosinstance";
import Context from "../../store/context";
const NoRampInfo = ({ show, onHide, confirm }) => {
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  const stepToRedeem = () => {
    const payload = {}
    axios
      .post(`api/v1/noramp/instructions`, payload)
      .then((response) => {
        confirm("show")
        // const toasterSuccess = {
        //   type: "success",
        //   message:
        //     "You have been sent an email explaining the steps to need to take to.",
        // };
        // globalDispatch({ type: "TOASTER", state: toasterSuccess });
      })
      .catch((error) => {
      });
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40 pt-0 center">
        <h3>NoRamp Wallet Integration</h3>
        <p>Congratulations on minting your Asset back NFT using your credit card!. To be able to sell or redeem your asset you need to follow a few simple steps. To send next steps to the email address associated with this account please press button below</p>
        <a onClick={() => stepToRedeem()}
          className="link-button"
          href="https://reveal.magic.link/NoRamp"
          target="_blank"
        >
          Steps to list and Redeem
        </a>
      </div>
    </Modal>
  );
};

export default NoRampInfo;
