import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import ScriptTag from 'react-script-tag';


const Truliookyc = () => {
    LoadingOverlay.propTypes = undefined
    let [loading, setLoading] = useState(true);
    const location = useLocation();
    let navigate = useNavigate();
    // const [bottleId, setBottleId] = useState();
    let bottleId;

    React.useEffect(() => {
        const [a, id] = location.search.split("?id=");

        bottleId = id;
        setTimeout(function () {
            setLoading(false);

        }, 3500)
        setInterval(() => {
            const kycToken = localStorage.getItem("deCodekyc");
            const airDropType =  localStorage.getItem("airDropType")
            if (kycToken) {
                if(airDropType==1){
                    navigate(`/airdropitem-details?id=${bottleId}?kycToken=${kycToken}`);
                    localStorage.removeItem('airDropType') 
                }else{
                    navigate(`/kyc?id=${bottleId}?kycToken=${kycToken}`);                   
                }
                localStorage.removeItem('deCodekyc')
            }
        }, 1000);
    }, []);


    return (
        <>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
                <div className="item-details ProductDetailsOne withoutHeader">
                    <div id="trulioo-embedid"></div>
                    <ScriptTag type="text/javascript" src="/assets/css/kyc.js" />
                </div>
            </LoadingOverlay>
        </>
    );
};

export default Truliookyc;
