import React, { useState } from "react";
import { useRef } from 'react';
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import HowtoWorks from "../components/layouts/home-6/HowtoWorks";
import avt from "../../src/assets/images/team/img_team.png";
import dummy from "../../src/assets/images/slider/user.png";
import jason from "../../src/assets/images/team/img_jason.png";
import eckhart from "../../src/assets/images/team/img_eckhart.png";
import steve from "../../src/assets/images/team/img_steve.png";
import ariyan from "../../src/assets/images/team/img_ariyan.png";

import arvindIyer from "../../src/assets/images/team/arvindIyer.jpeg";
import step from "../../src/assets/images/team/stephen.png";
import osy from "../../src/assets/images/team/img_about_us.png";
import andresQuintero from "../../src/assets/images/team/andres-Quintero.jpg";
import bobgrenon from "../../src/assets/images/team/bob-grenon.jpg";
import paulrobertson from "../../src/assets/images/team/paul-robertson.jpg";
import coreywendling from "../../src/assets/images/team/coreywendling.jpg";
import georgeyoumasiou from "../../src/assets/images/team/george-toumasiou.jpg";
import { Link, withRouter } from 'react-router-dom';
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
const Aboutus = () => {
  LoadingOverlay.propTypes = undefined
  let [loading, setLoading] = useState(true);
  const [brandData, setBrandData] = useState([]);
  React.useEffect(() => {

    //getBrandList();
  }, []);

  //   const getBrandList = () => {
  //     axios
  //       .get(`admin/v1/brand`)
  //       .then((response) => {
  //         setBrandData(response.data.data.listing);
  //         setLoading(false);

  //       })
  //       .catch((error) => {

  //       });
  //   };

  return (
    <>
      <section className='flat-title-page inner'>
        <h1 className="heading text-white">About us</h1>
        <p>Paiverse has brought together a creative and passionate team to work tirelessly to bring rare and unique assets to everyone.</p>
      </section>
      <div className="darkGray">
        <section className='ourStory tf-section'>
          <div className="themesflat-container">
            <div className='ourStoryImg '>
              <Link to={'/ProductDetails'}><img src={osy} alt='Our Story Image' /> </Link>
            </div>
            <div className='ourStoryCont text-justify '>
              <h3 className="heading text-center text-white"> Our Story </h3>
              <p>The Paiverse<sup>TM</sup> or Paladin’s universe is the amalgamation of old and new world concepts – it is a platform with its own IP -  “Infinite Possibilities<sup>TM</sup>”.  It is where the virtual (Meta) world intertwines with the real (Physical) world. It is this nexus for which we coined the concept, the “<span className="font-weight-bold">Meta-phy-verse</span><sup>TM</sup>” or <span className="font-weight-bold">METAPHY</span><sup>TM</sup> for short!</p>

              <p>Our platform is where would-be entrepreneurs, collectors, connoisseurs, admirers and individuals can participate in physical assets that are rare, unique, and curated from sources that are otherwise unavailable to mere mortals, irrespective of your location, fully verified and completely traceable via blockchain technologies. </p>


              <p>The Paiverse is derived from our name, “Paladin”, pronounced /palədn/, and embodies our ethos. The paladins (or Twelve Peers) were twelve fictional knights of legend, the foremost members of Charlemagne’s court in the 8th century AD. The word Paladin was first used in Ancient Rome for a chamberlain (advisor) of the Emperor, and also for the imperial palace guard, called the Scholae Palatinae by Constantine.
              </p>
              <p>We chose our namesake because we want to protect your NFTs by providing you access to physical assets that are ethically obtained, fully verifiable, transparently tradeable, absolutely immutable and finally redeemable at your convenience. We scour far and wide to bring unique physical assets that are collectables and also have ownership and verification provenance. Once you purchase them, you will never have to worry about insurance, security or storage. Finally,  we give you the ability to trade these assets as non-fungible tokens (NFTs) if you so choose, irrespective of your borders. </p>
              <p>In 2022 our co-founders, two compliance professionals with over 40 years of combined experience in the energy, commodities and financial trading markets, decided there was a better way to purchase physical assets (as NFTs) that are not merely gimmicks! We assembled a team of like-minded top-tier industry professionals who are experts in their fields  and set about building a platform that has all the benefits of an NFT marketplace (traceability, liquidity and immutability) and combined it, for the first time, with physical assets to back them,  giving our NFTs a quantifiable value! They created Paladin Digital Assets Inc., via the Paiverse –  which is Paladin’s Meta-phy-verse.
              </p>
              <p>Our desire to grow and scale is unparalleled, and we aim to become the most liquid asset-backed NFT marketplace on the planet. We have a deep passion for bringing some of the rarest assets to the market as NFTs and at the same time enable its holders to engage in global experiences to enjoy their assets and share the stories behind them being part of a unique community. Owing to our Compliance and Legal backgrounds, we are also working diligently to become a regulated financial entity in the near future in order to be one of the first Web 3.0 companies to have this sort of licensure! Of course, regulatory approval is quite a diligent and involved process which takes time, but the groundwork has been laid and our team is working in parallel to get the approvals over the next several months. </p>
              <p>We take our ESG (Environmental, Social and Governance) objectives quite literally too, being a Web 3.0 platform. To that end, we have core principles around our inception
              </p>
              <p>1. Environmental – we work with environmentally conscious partners and ensure this is embedded throughout our supply chain of assets! Check our partners’ credentials about our individual drops.
              </p>
              <p>2. Sustainability – We specifically chose Ethereum blockchain because version 2.0 is more energy efficient, less gas fees and is easier on the planet, even though full benefits of Blockchain (as opposed to its more famous cousins).
              </p>
              <p>3. Governance – as explained prior we are looking to become a regulated entity so that we abide by strict compliance and investor protection rules to ensure our platform users have complete peace of mind.
              </p>
              <p>Our team of experts who are spread throughout the globe have a deep understanding of the NFT and Physical asset spaces, and we look forward to showing all that we have to offer! </p>
              <p className="font-weight-bold">We are Paiverse and we exist in the METAPHY. It is a place with “Infinite Possibilities”.
              </p>

            </div>

          </div>
        </section>

        <section className='paiverseTeam'>
          <div className="themesflat-container">
            <h2 className="tf-title pb-40 text-center"> ABOUT THE PALADIN TEAM</h2>

            <div className="row text-center">
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={avt} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Tim Bhatnagar - Co-Founder</h5>
                    <p className="mb-2 text-justify">Based in the UK, Tim has over 15 years of experience in Energy Trading, Investment Banking and Asset Management. Over the last several years Tim has been working in the regulatory Compliance field. Passionate and active in beverages circle already, Tim has built consummate knowledge in exotic beverages and other alternative asset classes (art, watches and real estate). Together with a world class management team, Tim is very passionate about educating the world about the benefits of alternative assets.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={steve} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Steve Ariyan - Co-Founder</h5>
                    <p className="mb-2 text-justify">Based in the USA, Steve has been a practicing attorney for over 20 years advising C-Suite executives and Boards of Directors on legal, compliance and regulatory matters. He has a passion for blockchain technology and digitizing physical assets for everyone, no matter your location, to enjoy. Steve’s other passion is classic and exotic automobiles and is looking forward to Paiverse’s first car NFT launch in the near future!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={jason} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Jason Singh - Chief Disruption Officer</h5>
                    <p className="mb-2 text-justify">Based in Hong Kong, Jason has over 20 years’ experience in trading in Europe and Asia across Energy, Agriculture, Metals and most recently Crypto currency. Jason has a passion for the cryptocurrency market and has been involved in the area since 2017. Jason’s knowledge and passion within this area will help build Paladin’s NFT based community with its investors and followers.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={ariyan} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Dr. Siddharth (Sid) Hegde - Chief Data Scientist</h5>
                    <p className="mb-2 text-justify">Based in India, Sid has an exceptionally strong background in academia and research. Armed with a doctorate in Astrophysics and with over 15 years of research experience, Sid has worked to address complex problems at institutes such as NASA, University College London, Max Planck Institute, and Cornell University, among others. At Paladin, Sid provides strategic advice and helps build statistical and forecasting models across a range of our asset class offerings. </p>
                  </div>
                </div>
              </div>

             
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={georgeyoumasiou} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">George Toumasiou- Global Head of Sales </h5>
                    <p className="mb-2 text-justify">Based in the UK, George has over 15 years of experience in sales; predominantly in real estate but has recently turned to alternative investments. A dynamic and highly motivated individual who is extremely conscientious, patient and service driven. Operating with transparency, attention to detail and clear communication. George is keenly customer-focused, an empathic listener who understands the needs and wants of a diverse client base.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={andresQuintero} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Andres Quintero- Head of Sales, Americas </h5>
                    <p className="mb-2 text-justify">Based in Colombia S.A., Andrés has a passion for whisky NFTs and is ready to take the Latin American and Caribbean investors on this passion journey.
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <h2 className="tf-title pb-40 mg-t-42 text-center">Board of Advisors </h2>

            <div className="row text-center">
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={step} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Stephen McIntyre</h5>
                    <p className="mb-2 text-justify">Based in the USA, Mr. McIntyre is a Chief Executive Officer with a demonstrated history of working in the telecommunications industry. He is skilled in Business Development, Wireless Technologies, Telecommunications, Wholesale, and Sales Management. He brings a strong business development background with a MBA-International Management focused in International Business from Thunderbird School of Global Management. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={paulrobertson} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Paul Robertson</h5>
                    <p className="mb-2 text-justify">Based in the USA, Mr. Robertson has over 20 years working with premium brands with a focus on the Information Technology, Healthcare and Retail Sectors. Driven to deliver unbeatable value to his clients and developing Innovating engagement solutions for Sr. leadership in marketing, sales, C-Suite.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={bobgrenon} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Bob Grenon</h5>
                    <p className="mb-2 text-justify">Based in the USA, Mr. Grenon has been in the Fine Arts industry for 30 years. He has worked with numerous blue-chip and emerging artists in the primary and secondary markets, focusing on modern and contemporary art. Robert has operated numerous galleries and worked extensively with auction houses around the world developing opportunities for clients and artists alike. This breadth of experience has enabled him to evolve with the art markets as they have expanded from the more traditional gallery and auction houses to the online auctions and platforms.</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 mb-5">
                <div className="card">
                  <div className="card-body py-4 mt-2">
                    <div className="d-flex justify-content-center mb-4 imgbox">
                      <img src={dummy} alt='Team Name' />
                    </div>
                    <h5 className="font-weight-bold my-5">Deana Concilio-Lenz</h5>
                    <p className="mb-2 text-justify">Based in the USA, Mrs. Lenz has over 20 years’ experience creating content, ideating, and directing experiences from global non-profit organizations to music and media companies with expertise in facilitating the success of creating ongoing client relationships. Innovative professional, with exceptional people skills and remarkable ability to motivate a team toward a results-oriented goal.</p>
                  </div>
                </div>
              </div> */}


            </div>
          </div>
        </section>
      </div>


      {/* <HowtoWorks /> */}

      <section className='insurance darkGray tf-section' id="shipping1">
        <div className="themesflat-container">
          <div className='insuranceCont text-justify '>
            <h2 className="tf-title pb-40 text-center">Shipping & Insurance</h2>
            <p>Paiverse partners with reputable and professional shipping partners. All assets come fully insured to your place of location.  The exact shipping partner/carrier will be finalized based on multiple factors which include but aren’t limited to - Location of delivery, the first port of entry, and space available with the carrier.</p>
          </div>
        </div>
        <div className='mt-40'>
          <PaiverseCommunity />
        </div>
      </section>
    </>
  );
};

export default Aboutus;
