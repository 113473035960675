import React, { useRef, useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menus, Profilemenu } from "../../pages/menu";
import logodark from "../../assets/images/logo/logo.png";
import Context from "../../store/context";
import apiUrl from "../../store/globalapiurl"
import axios from "../../axiosinstance";
import Web3 from 'web3';
const HeaderStyle2 = () => {
  let navigate = useNavigate();
  const [activeClass, setactiveClass] = useState([])
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  const [globalHeaderMenu, setGlobalHeaderMenu] = useState([]);
  const [profileHeaderMenu, setProfileHeaderMenu] = useState([]);
  const [pathname1, setPathname1] = useState("");
  const [userName, setUserName] = useState([]);
  let { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isShowSearch, setShowSearch] = useState(true);
  const [walletAmount, setwalletAmount] = useState("");
  const headerRef = useRef(null);
  useEffect(async () => {
    setGlobalHeaderMenu(menus);
    setProfileHeaderMenu(Profilemenu)
    getBrandList();
    setSearchQuery(globalState.searchQuery);
    if (globalState.isLoggedIn) {
      getConnectWallet1();
    }
    checkTermsBanner();
  }, [globalState]);
  const getBrandList = () => {
    const payload = {};
    axios
      .get(apiUrl._getBrand, {
        params: payload,
      }).then((res) => {
        const menuData = [];
        res.data.data.map(item => {
          let str = item.name;
          str = str.replace(/\s+/g, '-').toLowerCase();
          menuData.push({ 'id': item.id, 'sub': item.name, 'links': `/brands/${str}` })
        });
        menus.map(item => {
          if (item.name === "Brands") {
            item['namesub'] = menuData
          }
        });
        setGlobalHeaderMenu(menus);

      })
      .catch((error) => {
      });
  };


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userName = localStorage.getItem("userName");
    let paramsID = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (accessToken) {
      globalDispatch({ type: "LOGIN" });
    } else {
      globalDispatch({ type: "LOGOUT" });
      globalDispatch({ type: "search", state: "" });
    }
    setUserName(userName);
    if (pathname === `/releaseDetails/${paramsID}`) {
      pathname = "/release";
    }
    if (pathname === "/marketPlace-item-details") {
      pathname = "/marketplace";
    }
    if (pathname !== "/item-details" || pathname !== "/my-collection" || pathname !== "/wallet-connect" || pathname !== "/airdropitem-details") {
      setPathname1("")
    }
    if (pathname === "/item-details" || pathname === "/my-collection" || pathname === "/airdropitem-details") {
      pathname = "/my-collection";
      setPathname1("/my-collection");
    }
    if (pathname == "/profile") {
      setPathname1("/profile");
      setShowSearch(false);
    }
    if (pathname === "/favorites") {
      setPathname1("/favorites");
      setShowSearch(false);
    }
    if (pathname === "/wallet-connect") {
      setPathname1("/wallet-connect");
      setShowSearch(false);
    }
    if (pathname !== "/about-us" || pathname !== "/roadmap" || pathname !== "/brands/${paramsID}") {
      setActiveID("")
    }
    if (pathname === "/about-us" || pathname === "/roadmap") {
      setActiveID(27)
      setShowSearch(false);
    }
    if (pathname === `/brands/${paramsID}`) {
      setActiveID(2)
      setShowSearch(false);
    }
    if (
      pathname === "/latest-news" ||
      pathname === "/faq" ||
      pathname === "/about-us" ||
      pathname === "/brands" ||
      pathname === "/wallet-connect"
      || pathname === "/kyc"
    ) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
    setActiveIndex(pathname);
  }, [pathname]);

  const handleChange = (e) => {
    if (e.key === "Enter") {
      globalDispatch({ type: "search", state: e.target.value });
    }
  };
  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const stopPropagation = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.toggle('showSubmenu');

  };
  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
    setactiveClass('')
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeID, setActiveID] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [searchTitle, setSearchTitle] = useState();
  const [checkBanner, setCheckBanner] = useState();
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };
  //this.handleLoginKeyUp = this.keyUpHandler.bind(this, 'LoginInput');
  //////////////////////
  const getConnectWallet1 = () => {
    axios
      .get(`api/v1/user/connectUserWallet`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          if (Web3.givenProvider) {
            const web3 = new Web3(Web3.givenProvider);
            console.log("Web3.givenProvider", Web3.givenProvider)
            console.log("Web3.givenProvider", web3)
            web3.eth.getBalance(response.data.data.walletId).then((res) => {
              if (res) {
                setwalletAmount((res / 1000000000000000000).toFixed(4));
                //response.data.data.walletAmount = walletAmount;
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log('error.response::::', error);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
      });
  };
  ///////////////////////////

  const logout = () => {
    localStorage.clear();
    setActiveIndex("/login");
    globalDispatch({ type: "LOGOUT" });
    globalDispatch({ type: "search", state: "" });
    navigate(`/login`);
  };

  const checkTermsBanner = () => {
    let banner = localStorage.getItem("termBanner");
    if (!globalState.isLoggedIn) {
      if (banner) {
        ///////////////////////
        axios
          .get(`/api/v1/user/page?type=1`)
          .then((response) => {
            let updatedAt = response.data.data.updatedAt;
            var termDate = new Date(updatedAt).getTime();
            let bannerDate = new Date(localStorage.getItem("termBannerDate")).getTime()

            if ((termDate - bannerDate) > 0) {
              setCheckBanner('');
              localStorage.setItem("termBanner", '');
              localStorage.setItem("termBannerDate", "");
            } else {

            }
          })
          .catch((error) => {
          });
        //////////////////////
        setCheckBanner(banner);
      }
    } else {
      axios
        .get(`/api/v1/user/getPolicyAcceptStatus`)
        .then((response) => {
          let data = response.data.data.isPolicyAccepted;
          if (data == 0) {
            setCheckBanner('');
          } else {
            setCheckBanner('not-display');
          }
        })
        .catch((error) => {
        });
    }
  };

  function handleOnSubMenu(id) {
    if (id !== "noId") {
      localStorage.setItem("brandID", id)
    }
  }

  function handleOnSearch(title, id) {
    setSearchTitle('');
    setSearchResult([]);
    //this.setState({redirect: true});
    window.location.replace(`/releaseDetails/${id}`);
    //navigate(`/releaseDetails/${id}`, { state: `/releaseDetails/${id}` });
  }

  function termAndPolicyUpdate() {
    if (!globalState.isLoggedIn) {
      var today = new Date();
      localStorage.setItem("termBanner", 'not-display');
      localStorage.setItem("termBannerDate", today);
      setCheckBanner('not-display');
    } else {
      const payload = {
        policyAccepted: 1
      };
      axios
        .put(`api/v1/user/updateProfile`, {
          policyAccepted: 1
        })
        .then((response) => {
          setCheckBanner('not-display');
        })
        .catch((error) => {
        });
    }
  }

  const searchText = (name) => (event) => {
    const value = event.target.value;
    setSearchTitle(value);
  };

  function handleOnKeyUpSearch(x) {
    if (x.target.value !== "") {
      setSearchTitle(x.target.value);
      const payload = {
        search: x.target.value,
        skip: 0,
        limit: 50,
      };
      axios
        .get(`api/v1/liveStore`, {
          params: payload,
        })
        .then((response) => {
          let data = response.data.data.rows;
          setSearchResult(data);

        })
        .catch((error) => {
        });
    } else {
      setSearchResult([]);
    }
  }

  return (
    <>

      <header
        id="header_main"
        className="header_1 header_2 style2 js-header"
        ref={headerRef}
      >
        <div className={`top-banner ${checkBanner}`}>
          <div className="themesflat-container">
            <p> We updated the terms and conditions please  <a href="#" onClick={termAndPolicyUpdate}>Acknowledge</a> </p>
          </div>
        </div>


        <div className="themesflat-container">
          <div id="site-header-inner final">
            <div className="wrap-box flex">
              <div id="site-logo" className="clearfix">
                <div id="site-logo-inner">
                  <Link to="/" rel="home" className="main-logo">
                    <img id="logo_header" src={logodark} alt="nft-gaming" />
                  </Link>
                </div>
              </div>
              <div
                className="mobile-button"
                ref={btnToggle}
                onClick={menuToggle}
              >
                <span></span>
              </div>
              <nav id="main-nav" className="main-nav navigationWrap" ref={menuLeft} onClick={menuToggle}>
                <ul id="menu-primary-menu" className="menu" >
                  {globalHeaderMenu.map((data, index) => (
                    <li
                      key={index}
                      onClick={() => handleOnClick(data.links)}
                      className={`menu-item ${data.namesub ? "menu-item-has-children" : ""
                        } ${data.namesub ? "" : activeIndex === data.links ? "active" : ''} `}
                    >
                      {data.namesub && (
                        <a className={`subMenuTitle  ${data.id === activeID ? "active" : ''} `} href="#" onClick={stopPropagation} >{data.name} </a>
                      )}
                      {!data.namesub && (
                        <Link to={data.links}>{data.name}</Link>
                      )}
                      {data.namesub && (
                        <ul className="sub-menu">
                          {data.namesub.map((submenu) => (
                            <li
                              key={submenu.id}
                              className={
                                pathname === submenu.links
                                  ? "menu-item current-item"
                                  : "menu-item"
                              }
                            >
                              {/* onClick={(e)=>e.target.parentElement.parentElement.parentElement.classList.add('active')} */}
                              <Link to={submenu.links} onClick={() => handleOnSubMenu(submenu.id ? submenu.id : 'noId')}>{submenu.sub}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}

                </ul>
              </nav>

              <div className="flat-search-btn flex">
                {globalState && globalState.isLoggedIn ? (
                  <nav id="main-nav" className="main-nav profile-nav">
                    <ul id="menu-primary-menu" className="menu">
                      <li className="menu-item menu-item-has-children">
                        <div className="search-wrap">
                          <input type="text" placeholder="Search.." name="searchInput" onKeyUp={handleOnKeyUpSearch} autoComplete="off" onChange={searchText(searchTitle)} value={searchTitle} />
                          <ul className="autocomplete">
                            {searchResult.map((result) => (<li><a href="#" onClick={() => handleOnSearch(result.title, result.id)}> {result.title}</a></li>))}
                          </ul>
                        </div>
                      </li>
                      {profileHeaderMenu.map((data, index) => (
                        <li
                          key={index}
                          onClick={() => handleOnClick(data.links)}
                          className={`menu-item ${data.namesub ? "menu-item-has-children" : ""
                            } ${activeIndex === data.links ? "current-item" : ""
                            } `}
                        >
                          <Link to="" onClick={() => setactiveClass(activeClass ? ('') : globalState.userName)} className={`userInfo ${activeClass == globalState.userName && 'showSubmenu'}`}>
                            {globalState && globalState.userName}{" "}
                          </Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li
                                  key={submenu.id}
                                  className={
                                    pathname1 === submenu.links
                                      ? "menu-item current-item"
                                      : `menu-item`
                                  }
                                >
                                  <Link to={submenu.links} onClick={() => setactiveClass('')}>
                                    {submenu.sub}  {(submenu.id == 3) ? walletAmount ? '( ' + walletAmount + ' ETH )' : '' : ''}
                                  </Link>

                                </li>
                              ))}
                              <li>
                                <a className="pointer" onClick={logout}>
                                  Logout
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>
                      ))}

                    </ul>
                  </nav>
                ) : (
                  <nav id="main-nav" className="main-nav profile-nav guestUser">
                    <ul id="menu-primary-menu" className="menu">
                      <li className="menu-item menu-item-has-children">
                        <div className="search-wrap">
                          <input type="text" placeholder="Search.." name="searchInput" onKeyUp={handleOnKeyUpSearch} autoComplete="off" onChange={searchText(searchTitle)} value={searchTitle} />
                          <ul className="autocomplete">
                            {searchResult.map((result) => (<li><a href="#" onClick={() => handleOnSearch(result.title, result.id)}> {result.title}</a></li>))}
                          </ul>
                        </div>
                      </li>
                      <li
                        key="2"
                        onClick={() => handleOnClick("/sign-up")}
                        className={`menu-item ${activeIndex === "/sign-up" ? "active" : ""
                          } `}
                      >
                        <Link to="/sign-up">Signup</Link>
                      </li>
                      <li><a>/</a></li>
                      <li
                        key="1"
                        onClick={() => handleOnClick("/login")}
                        className={`menu-item ${activeIndex === "/login" ? "active" : ""
                          } `}
                      >
                        <Link to="/login">Login</Link>
                      </li>
                    </ul>
                  </nav>
                )}


              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle2;
