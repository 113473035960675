import React from "react";
const PaiverseCommunity = () => {
    return (
        <div className="themesflat-container">
            <div className="communityWrap">
                <div className="leftWrap">
                    <h3>Paiverse Community</h3>
                    <p>Become part of a family. Be first to know about<br /> upcoming drops, deals, and giveaways</p>
                </div>
                <a href="https://discord.gg/Tjp2vjmyEj" target="_blank" rel="noreferrer" className="joinBtn">Join Discord</a>
            </div>
        </div>
    );
};

export default PaiverseCommunity;
