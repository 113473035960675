import React, { useState } from "react";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import bottle from "../../src/assets/images/product_details/img_bottle1.png";
import heartW from "../../src/assets/images/product_details/ic_heart.png";
import ownedPic from "../../src/assets/images/product_details/img_Aista.png";
import bot from "../../src/assets/images/box-item/bottle1.jpg";
import HomeActiveity from "../components/layouts/home-6/homeActiveity";
import MarketFaq from "./marketplaceFaq";
import { Link } from 'react-router-dom';
import img1 from "../../src/assets/images/slider/bottle4.jpeg";
import imga1 from "../../src/assets/images/slider/user.png";
const MarketDetails = () => {
    const [data] = useState([
        {
            img: img1,
            title: "New Bottle",
            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },
        {
            img: img1,
            title: "New Bottle",

            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },
        {
            img: img1,
            title: "New Bottle",
            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },
        {
            img: img1,
            title: "New Bottle",

            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },
        {
            img: img1,
            title: "New Bottle",

            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },
        {
            img: img1,
            title: "New Bottle",
            imgAuthor: imga1,
            nameAuthor: "Jackson Jack",
            price: "4.89 ETH",
            wishlist: "100",
        },

    ]);

    return (
        <>
            <div className='ProductDetailsOne withoutHeader'>
                <section className='Penderyn  tf-section'>
                    <div className="themesflat-container">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='imgBox'>
                                    <img src={bottle} alt="Bottle" width="350px" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='contBox'>
                                    <span className='views'> 1837 views </span>
                                    <h2> NFT Penderyn 26 </h2>
                                    <p> Since 2016 Penderyn has won over 35 Goldenawards and numerous silvers and almost all this has been achieved with their no age statement spirits! What with their success, the distillery has decided to share a very limited and collectable 25 bottles from one of its rarest ex-bourbon single malt </p>

                                    <button className=' roundBtn mr-5'> Buy Now </button>
                                    <button className=' borderBtn '> <img src={heartW} alt="Bottle" width="20px" className='mr-3' /> 100 </button>

                                    <div className='ownedBy d-flex mt-4'>
                                        <div className='ownedPic mr-5'>
                                            <img src={ownedPic} alt="Bottle" width="70px" />
                                        </div>
                                        <div className='ownedInfo'>
                                            <p> Owned By </p>
                                            <h6> Jackson Jack </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='ownerList tf-section'>
                    <div className="themesflat-container">
                        <div className='row'>
                            <div className='col-md-6 col-12'>
                                <MarketFaq />
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="marketDetailsListing ">
                                    <h3 className="text-white subheading">Listing by the owner</h3>
                                    <div className="row">
                                        {data.map((item) => (
                                            <div className="col-md-4 col-sm-6 col-12">
                                                <div className="sc-card-product">
                                                    <div className="card-media">
                                                        <Link to="/item-details-01">
                                                            <img src={item.img} alt="axies" />
                                                        </Link>
                                                        <Link
                                                            to="/login"
                                                            className="wishlist-button heart"
                                                        >
                                                            <span className="number-like">
                                                                {item.wishlist}
                                                            </span>
                                                        </Link>

                                                    </div>
                                                    <div className="card-title d-flex">
                                                        <div className="left">
                                                            <h3>
                                                                <Link to="/item-details-01">
                                                                    {item.title}
                                                                </Link>
                                                            </h3>
                                                            <div className="price">
                                                                Price 100 USD  <span>({item.price})</span>
                                                            </div>

                                                        </div>

                                                        <div className="button-place-bid">
                                                            <button
                                                                className="tags"
                                                            >Buy
                                                                {/* <span>Place Bid</span> */}
                                                            </button>
                                                        </div>

                                                    </div>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="avatar">
                                                                <img src={item.imgAuthor} alt="axies" />
                                                            </div>
                                                            <div className="info">
                                                                <span>Owner</span>
                                                                <h4>
                                                                    {" "}
                                                                    <Link to="#">{item.nameAuthor}</Link>{" "}
                                                                </h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className="offerWrap ">
                                    <h3 className="text-white subheading">Offers</h3>
                                    <div className="tableWrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Edition</th>
                                                    <th>Status</th>
                                                    <th>Owner</th>
                                                    <th>Price</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        My Grain #11
                                                    </td>
                                                    <td>
                                                        Listed
                                                    </td>
                                                    <td>
                                                        Jack
                                                    </td>
                                                    <td>
                                                        0.13 ETH
                                                    </td>

                                                    <td><a href="javascript:void(0)"className="offerBtn">Make an offer</a></td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        My Grain #11
                                                    </td>
                                                    <td>
                                                        Listed
                                                    </td>
                                                    <td>
                                                        Jack
                                                    </td>
                                                    <td>
                                                        0.13 ETH
                                                    </td>

                                                    <td><a href="javascript:void(0)" className="offerBtn">Make an offer</a></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        My Grain #11
                                                    </td>
                                                    <td>
                                                        Listed
                                                    </td>
                                                    <td>
                                                        Jack
                                                    </td>
                                                    <td>
                                                        0.13 ETH
                                                    </td>

                                                    <td><a href="javascript:void(0)" className="offerBtn">Make an offer</a></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        My Grain #11
                                                    </td>
                                                    <td>
                                                        Listed
                                                    </td>
                                                    <td>
                                                        Jack
                                                    </td>
                                                    <td>
                                                        0.13 ETH
                                                    </td>

                                                    <td><a href="javascript:void(0)" className="offerBtn">Make an offer</a></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <HomeActiveity />
                <PaiverseCommunity />
            </div>
        </>
    )
}

export default MarketDetails