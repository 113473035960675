import { baseUrl} from "./store/baseUrl"

import axios from "axios";
const instance = axios.create({
  baseURL: baseUrl,
});

// axios interceptor to append the authorization header
instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("accessToken");
    if (token) request.headers["Authorization"] = token;
    return request;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response, err) => { 
    return response;
  },
  ({ ...error }) => {
    // Hanle the common api error
    if (error.response.status === 401) {
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
