import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import HeaderStyle2 from "./components/header/HeaderStyle2";
import Footer from "./components/footer/Footer";
import GlobalStateProvide from "./store/globalStateProvider";
import LogoutTimer from "./pages/LogoutTimer";

ReactDOM.render(
  <GlobalStateProvide>
    <BrowserRouter>
      <LogoutTimer />
      <ScrollToTop />
      <HeaderStyle2 />
      <App />
      <Footer />
    </BrowserRouter>
  </GlobalStateProvide>,
  document.getElementById("root")
);
