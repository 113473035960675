import React, { useState, Fragment, useEffect, useContext } from "react";
import apiUrl from "../../../store/globalapiurl"
import axios from "../../../axiosinstance";
import Context from "../../../store/context";
import LoadingOverlay from "react-loading-overlay";
import { contractAddress } from "../../../Helpers/contract";
import { Scrollbars } from 'react-custom-scrollbars';
import { useNavigate } from "react-router-dom";
import * as moment from 'moment';
const HomeActiveity = () => {
    let navigate = useNavigate();
    LoadingOverlay.propTypes = undefined
    let [limit, setLimit] = React.useState(5);
    let [loading, setLoading] = useState(false);
    let [scrollHight, setScrollHight] = useState();
    const { commonGlobalState } = useContext(Context);
    const { globalState } = commonGlobalState;
    let [activeityData, setActiveityData] = useState([]);
    const [visible, setVisible] = useState(false);
    useEffect(async () => {
        limit = 5;
        setLimit(limit)
        getActivity();
    }, []);
    const getActivity = () => {
        const payload = {
            skip: 0,
            limit: limit,
        };
        axios
            .get(apiUrl._getActivity, {
                params: payload,
            }).then((res) => {
                setLoading(false)
                let data = res.data.data;
                setActiveityData(data);
                if (data.rows.length < res.data.data.count) {
                    scrollHight = 400
                    setScrollHight(scrollHight)
                    setVisible(true);
                } else {
                    scrollHight = (Number(data.rows.length * 80))
                    setScrollHight(scrollHight)
                    setVisible(false);
                }
            })
            .catch((error) => {
            });
    };
    const loadMore = () => {
        limit = limit + 5;
        setLimit(limit);
        setLoading(true)
        getActivity();

        // if (globalState.isLoggedIn) {
        //     navigate(`/my-collection?myactiveity=4`);
        // } else {
        //     navigate(`/login`, { state: `/my-collection?myactiveity=4` });
        // }
    };
    const estDate = (date) => {
        let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
        let newDate = moment(date1).format('YYYY-MM-DD');
        return newDate
    }
    const esttime = (date) => {
        let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
        let newDate = moment(date1).format('HH:mm A');
        let finalDate = newDate + " EST"
        return finalDate
    }

    return (
        <Fragment>
            <section className="tf-section">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-40 text-left">Activity</h2>
                    <div className="tableWrapper activityTable">
                        <table style={{ marginBottom: "0px" }} >
                            <thead>
                                <tr>
                                    <th width="100">NFT ID</th>
                                    <th style={{ width: "20%" }}>Title</th>
                                    <th className="breakWord">From</th>
                                    <th className="breakWord">To</th>
                                    <th width="100">Event</th>
                                    <th width="150"> Price </th>
                                    <th width="170">Date</th>

                                </tr>
                            </thead>
                        </table>
                        <div className="scrollTable">
                            <Scrollbars style={{ height: scrollHight }} 
                                renderThumbVertical={({ style, ...props }) =>
                                    <div {...props} className="scrollBarBG" />
                                }>
                                <table style={{ marginBottom: "0px" }} >
                                    <tbody>

                                        {activeityData.count && activeityData.rows.map((item, key) => (
                                            <tr key={key} >
                                                <td width="100">
                                                    #{item?.productItems.blockChainNFTId}
                                                </td>
                                                <td style={{ width: "20%" }} >
                                                    {item?.transactionProduct.title}
                                                </td>
                                                <td className="breakWord">
                                                    {item.to === contractAddress && item.totalAmount > 0 && ("0x00000000000000000000000000000000000")}

                                                    {item.to === contractAddress && item.totalAmount === 0 && ("0x00000000000000000000000000000000000")}

                                                    {item.to !== contractAddress && item.from !== contractAddress && (item.to)}
                                                    {item.from === contractAddress && (item.to)}
                                                </td>

                                                <td className="breakWord">

                                                    {item.from === contractAddress ? "0x00000000000000000000000000000000000" : item.from}
                                                </td>
                                                <td width="100">

                                                    {item.to === contractAddress && item.totalAmount > 0 ? "Mint" : ''}
                                                    {item.to !== contractAddress && item.from !== contractAddress ? "Buy" : ''}
                                                    {item.to === contractAddress && item.totalAmount === 0 ? "Airdrop" : ''}
                                                    {item.from === contractAddress ? "Burn" : ''}

                                                    {/* {item.sellerId === null ? ("Mint") : (item.sellerId === item.buyerId ? "Airdrop" : "Buy")} */}

                                                </td>

                                                <td width="150">{item.totalAmount} ETH</td>
                                                <td width="165">
                                                    {estDate(item.createdAt)}<br />
                                                    {esttime(item.createdAt)}

                                                </td>

                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </Scrollbars>
                        </div>


                        {!activeityData.count ? <div className="noData">No current activity</div> : ''}
                    </div>
                    {visible && (
                        <div className="d-flex justify-content-center">
                            <button className="exploreMore" onClick={loadMore}>  Load more</button>
                        </div>
                    )}

                </div>
            </section>

        </Fragment>
    );
};

export default HomeActiveity;
