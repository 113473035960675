import React, { useState, Fragment, useEffect } from "react";
import prodimg from "./../../../assets/images/product_details/img_bottle2.png"
import { Link } from "react-router-dom";
import apiUrl from "../../../store/globalapiurl"
import axios from "../../../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import * as moment from 'moment';
import { contractAddress } from "../../../Helpers/contract";

const MyActiveity = () => {
    LoadingOverlay.propTypes = undefined
    let [limit, setLimt] = React.useState(0);
    const loginID = localStorage.getItem("userID") || '';
    let [loading, setLoading] = useState(true);
    let [activeityData, setActiveityData] = useState([]);
    const [visible, setVisible] = useState(false);

    useEffect(async () => {
        limit = 10;
        setLimt(limit)
        getActivity();
    }, []);
    const getActivity = () => {
        setLoading(true);
        const payload = {
            skip: 0,
            limit: limit,
            userId: loginID
        };
        axios
            .get(apiUrl._getActivity, {
                params: payload,
            }).then((res) => {
                let data = res.data.data;
                setActiveityData(data)

                if (limit < res.data.data.count) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
                setLoading(false);               
            })
            .catch((error) => {
            });
    };
    const loadMore = () => {
        limit = limit + 10;
        setLimt(limit)
        getActivity();
    };

    const estDate = (date) => {
        let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
        let newDate = moment(date1).format('YYYY-MM-DD');
        return newDate
    }
    const esttime = (date) => {
        let date1 = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York', });
        let newDate = moment(date1).format('HH:mm A');
        let finalDate = newDate + " EST"
        return finalDate
    }

    return (
        <Fragment>
            <div className="themesflat-container">

                <div className="tableWrapper activityTable">
                    <table>
                        <thead>
                            <tr>
                                <th width="100">NFT ID</th>
                                <th>Title</th>
                                <th className="breakWord">From</th>
                                <th className="breakWord">To</th>
                                <th width="100">Event</th>
                                <th width="150"> Price </th>
                                <th>Date</th>

                            </tr>
                        </thead>
                        <tbody>
                            {activeityData.count && activeityData.rows.map((item, key) => (
                                <tr key={key} >
                                    <td width="100">
                                        #{item?.productItems.blockChainNFTId}
                                    </td>
                                    <td>
                                        {item?.transactionProduct.title}
                                    </td>
                                    <td className="breakWord">
                                        {item.to === contractAddress && item.totalAmount > 0 &&("0x0000000000000000000000000000000000000")}

                                        {item.to === contractAddress && item.totalAmount === 0 &&("0x0000000000000000000000000000000000000")}

                                        {item.to !== contractAddress && item.from !== contractAddress &&(item.to)}
                                        {item.from === contractAddress && (item.to )}
                                    </td>

                                    <td className="breakWord">
                                       
                                        {item.from === contractAddress ? "0x0000000000000000000000000000000000000" : item.from}
                                    </td>
                                    <td width="100">

                                        {item.to === contractAddress && item.totalAmount > 0 ? "Mint" : ''}
                                        {item.to !== contractAddress && item.from !== contractAddress ? "Buy" : ''}
                                        {item.to === contractAddress && item.totalAmount === 0 ? "Airdrop" : ''}
                                        {item.from === contractAddress ? "Burn" : ''}

                                        {/* {item.sellerId === null ? ("Mint") : (item.sellerId === item.buyerId ? "Airdrop" : "Buy")} */}

                                    </td>

                                    <td width="150">{item.totalAmount} ETH</td>
                                    <td>
                                        {estDate(item.createdAt)}<br />
                                        {esttime(item.createdAt)}

                                    </td>

                                </tr>
                            ))}


                        </tbody>
                    </table>
                    {!activeityData.count ? <div className="noData">No current activity</div> : ''}


                </div>
                {visible && (
                    <div className="d-flex justify-content-center">
                        <Link to="#" className="exploreMore" onClick={loadMore}>
                            Explore More
                        </Link>
                    </div>
                )}
            </div>


        </Fragment>
    );
};

export default MyActiveity;
