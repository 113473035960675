 const apiUrl = {
    _liveStore:'/api/v1/liveStore',
    _liveStoreDetails:'/api/v1/liveStore/',
    _getBrand:'api/v1/user/getBrands',
    _getBrandDetails:'api/v1/user/brandDetail/',
    _getFilter:'/api/v1/marketPlace/filterStaticData',
    _getActivity:'/api/v1/user/myActivity',
    _getPage: '/api/v1/user/page',
    _getLatestNews: '/admin/v1/news'
}
export default apiUrl;